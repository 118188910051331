import { OTO_PRODUCTS } from 'enums';
import type { INavItem } from './types';
import { ROLES } from 'enums';
import { RESTAURANT_IDS_WITH_ACCESS_TO_RESERVATION_CUSTOMERS } from 'app/pages/ReservationCustomersPage';

export const reservationNavItems: INavItem[] = [
  {
    name: 'Lista rezerwacji',
    icon: 'Calendar',
    url: '/calendar',
    availableWithProducts: [OTO_PRODUCTS.reservations],
  },
  {
    name: 'Dodaj rezerwację',
    icon: 'Plus',
    url: '/add-reservation',
    availableWithProducts: [OTO_PRODUCTS.reservations],
  },
  {
    name: 'Mapa stolików',
    icon: 'MapPin',
    url: '/place-map-view',
    availableWithProducts: [OTO_PRODUCTS.reservations],
  },
  {
    name: 'Znajdź rezerwację',
    icon: 'Search',
    url: '/find-reservation',
    availableWithProducts: [OTO_PRODUCTS.reservations],
    availableForRoles: [ROLES.ADMIN, ROLES.RESTAURANT_ADMIN],
  },
  {
    name: 'Lista klientów',
    icon: 'User',
    url: '/reservation-customers',
    availableWithProducts: [OTO_PRODUCTS.reservations],
    availableForRoles: [ROLES.ADMIN, ROLES.RESTAURANT_ADMIN],
    availableForRestaurantIds: RESTAURANT_IDS_WITH_ACCESS_TO_RESERVATION_CUSTOMERS,
  },
];
