import { CollapsibleCard, OtoButtons } from 'app/components/common';
import React, { useMemo } from 'react';
import { ISavedReservation } from 'types';
import { downloadReservationsCSV } from 'utils/csv';

export const ReservationsMarketingAgreementStatistics: React.FC<{
  reservations: ISavedReservation[];
}> = ({ reservations }) => {
  const {
    reservationsWithBothAgreements,
    reservationsWithEmailAgreement,
    reservationsWithSmsAgreement,
    uniqueEmailsWithMarketingAgreement,
    uniquePhoneNumbersWithMarketingAgreement,
  } = useMemo(() => {
    const reservationsWithSmsAgreement = reservations
      .filter((reservation) => reservation.sms_marketing)
      .map((reservation) => ({
        ...reservation,
        email: '-',
      }));
    const reservationsWithEmailAgreement = reservations
      .filter((reservation) => reservation.email_marketing)
      .map((reservation) => ({
        ...reservation,
        phone_number: '-',
      }));
    const reservationsWithBothAgreements = reservations.filter(
      (reservation) => reservation.sms_marketing && reservation.email_marketing
    );

    const uniquePhoneNumbersWithMarketingAgreement: string[] = [
      ...new Set(
        reservationsWithSmsAgreement.map(
          (reservation) => reservation.phone_number
        )
      ),
    ];

    const uniqueEmailsWithMarketingAgreement: string[] = [
      ...new Set(
        reservationsWithEmailAgreement
          .map((reservation) => reservation.email)
          .filter(Boolean) as string[]
      ),
    ];

    return {
      reservationsWithBothAgreements,
      reservationsWithSmsAgreement,
      reservationsWithEmailAgreement,
      uniqueEmailsWithMarketingAgreement,
      uniquePhoneNumbersWithMarketingAgreement,
    };
  }, [reservations]);
  return (
    <CollapsibleCard
      buttonClassName={'p-1'}
      className={'my-2 mw-600'}
      title={'Statystyki zgód marketingowych'}
    >
      <div>
        Liczba rezerwacji ze zgodą SMS: {reservationsWithSmsAgreement.length} (w
        tym {uniquePhoneNumbersWithMarketingAgreement.length} unikalnych numerów
        telefonów)
      </div>
      <OtoButtons.DownloadButton
        onClick={() =>
          downloadReservationsCSV(
            reservationsWithSmsAgreement,
            'rezerwacje-ze-zgodami-sms.csv'
          )
        }
      >
        {'Pobierz CSV'}
      </OtoButtons.DownloadButton>

      <hr />

      <div>
        Liczba rezerwacji ze zgodą email:{' '}
        {reservationsWithEmailAgreement.length} (w tym{' '}
        {uniqueEmailsWithMarketingAgreement.length} unikalnych adresów email)
      </div>
      <OtoButtons.DownloadButton
        onClick={() =>
          downloadReservationsCSV(
            reservationsWithEmailAgreement,
            'rezerwacje-ze-zgodami-email.csv'
          )
        }
      >
        {'Pobierz CSV'}
      </OtoButtons.DownloadButton>

      <hr />

      <div>
        Liczba rezerwacji ze zgodą na SMS i email:{' '}
        {reservationsWithBothAgreements.length}
      </div>
      <OtoButtons.DownloadButton
        onClick={() =>
          downloadReservationsCSV(
            reservationsWithBothAgreements,
            'rezerwacje-z-obu-zgodami.csv'
          )
        }
      >
        {'Pobierz CSV'}
      </OtoButtons.DownloadButton>
    </CollapsibleCard>
  );
};

export default ReservationsMarketingAgreementStatistics;
