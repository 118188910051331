import React from 'react';
import i18next from 'i18next';

import { currency } from 'globals/currency';
import { fixDecimal } from 'utils/general';
import { getOrderCost } from 'utils/orders';
import Shapes from 'shapes/main';
import type { IOrder } from 'types';

type OrderTotalProps = {
  order: IOrder;
  showPrefix?: boolean;
};

export const OrderTotal: React.FC<OrderTotalProps> = ({
  order,
  showPrefix = true,
}) => {
  const { deliveryCost, itemsCost, totalCost } = getOrderCost(order);
  return (
    <div>
      {showPrefix && (
        <>
          <strong>{i18next.t('order.total')}:</strong> {fixDecimal(totalCost)}{' '}
          {currency}
        </>
      )}
      ({fixDecimal(itemsCost)} + {deliveryCost} za dostawę)
    </div>
  );
};

OrderTotal.propTypes = {
  order: Shapes.orderShape.isRequired,
};

OrderTotal.displayName = 'OrderTotal';

export default OrderTotal;
