import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Label,
} from 'reactstrap';
import i18next from 'i18next';

import { checkBoxStyle } from '../../../styles';
import {
  isDeclined,
  getKidsNumber,
  addPeopleAmountToTableTitle,
  generateReservedOn,
  convertSavedReservationToNormalized,
} from 'utils/reservation';
import { mapLabelsToDeclineResons } from '../lang';
import {
  canSelectDuration,
  getDurationOptions,
  hasAssignee,
  hasFlexibleTables,
  hasKids,
} from 'utils/reservation';
import OtoSpinner from '../../common/OtoSpinner';
import Shapes from 'shapes/main';
import { possibleReservationDurations } from 'config';
import Restaurant from 'utils/restaurant';
import type {
  ISavedFile,
  ISavedReservation,
  NormalizedRestaurant,
  ParamsReservationEdit,
  ReservationConfig,
  TDraftReservation,
  ITable,
  TTableWithPeopleAmount,
  TTableSuggestion,
} from 'types';
import ErrorBoundary from 'app/containers/ErrorBoundary';
import ReservationAttachments from '../ReservationAttachments';
import { isIOS, isSafari } from 'utils/device';
import TimePicker from '../../common/TimePicker';
import TablesFormatted from '../TablesFormatted';
import AddReservationTablesSelect from '../AddReservationTablesSelect';
import type { TablesAvailability } from 'store/reservation-calendar';

import './ReservationsModal.scss';
import OtoError from 'app/components/common/OtoError';

interface IField {
  label: string;
  key: string;
  type?: 'tel' | 'date' | 'number' | 'text' | 'time' | 'email';
  default?: string;
  inputClassName?: string;
  isValid?: (value: string | number) => boolean;
  showOnlyWhenValuePresent?: boolean;
  staticClassName?: string;
}

const getBaseModalFields = (): IField[] => [
  { label: i18next.t('reservation.guest_name'), key: 'customer_name' },
  {
    label: i18next.t('reservation.phone_number'),
    key: 'phone_number',
    type: 'tel',
    inputClassName: 'mw-200',
  },
  {
    label: i18next.t('reservation.email'),
    key: 'email',
    type: 'email',
    inputClassName: 'mw-200',
    showOnlyWhenValuePresent: true,
  },
  {
    label: i18next.t('reservation.visit_date'),
    key: 'visitDate',
    type: 'date',
    inputClassName: 'mw-200',
    staticClassName: 'mt-2 pt-2 bt-1 bc-grey',
  },
  {
    label: i18next.t('reservation.visit_time'),
    key: 'visitTime',
    inputClassName: 'mw-150',
    type: 'time',
  },
  {
    label: i18next.t('reservation.guests_number'),
    key: 'guests_number',
    type: 'number',
    inputClassName: 'mw-150',
    isValid: (value: string | number): boolean => {
      if (!value) {
        return false;
      }
      return typeof value === 'number' ? value > 0 : parseInt(value, 10) > 0;
    },
  },
];

const getCreatedAtStaticField: () => IField = () => ({
  label: i18next.t('reservation.created_at'),
  key: 'created_at',
  type: 'date',
  staticClassName: 'mb-2',
});

interface IReservationsModalProps {
  reservation: ISavedReservation;
  restaurant: NormalizedRestaurant;
  config: ReservationConfig;
  tables: ITable[] | null;
  onSave: (
    reservation: ISavedReservation,
    payload: Partial<ParamsReservationEdit>
  ) => Promise<ISavedReservation | null>;
  onClose: () => void;
  onCancel: (reservation: ISavedReservation) => void;
  onLogError?: (message: string, data?: Record<string, unknown>) => void;
  renderFileUpload: (props: {
    onSuccess: (file: ISavedFile) => void;
  }) => React.ReactNode;
  reportNoShow: (reservation: ISavedReservation) => void;
  suggestedTables: TTableWithPeopleAmount[];
  tableSuggestionsLoading: boolean;
  loadTableSuggestions: (
    params: {
      duration: number;
      guests_number: number;
      visitDate: string;
      visitTime: string;
    },
    availability: TablesAvailability
  ) => Promise<TTableSuggestion[]>;
}

export type TEditReservationValues = Exclude<TDraftReservation, 'tables'> & {
  tables?: ITable[] | null;
};

interface IReservationsModalState {
  editing: boolean;
  loading: boolean;
  values: TEditReservationValues;
}

export default class ReservationsModal extends React.PureComponent<
  IReservationsModalProps,
  IReservationsModalState
> {
  tableOptionsWithPeopleAmount?: TTableWithPeopleAmount[];

  static propTypes = {
    config: Shapes.reservationConfigShape.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    reportNoShow: PropTypes.func.isRequired,
    reservation: Shapes.savedReservationShape.isRequired,
    restaurant: Shapes.restaurantShape.isRequired,
  };

  constructor(props: IReservationsModalProps) {
    super(props);
    const { config, reservation, tables } = props;
    this.state = {
      editing: false,
      loading: false,
      values: {
        ...convertSavedReservationToNormalized(reservation, config),
      },
    };
    if (hasFlexibleTables(config) && tables) {
      this.tableOptionsWithPeopleAmount = tables.map((table) =>
        addPeopleAmountToTableTitle(table, {
          addPlace: true,
          allTables: tables,
        })
      );
    }
  }

  edit = () =>
    this.setState({
      editing: true,
      values: {
        ...convertSavedReservationToNormalized(
          this.props.reservation,
          this.props.config
        ),
      },
    });

  saveEdit = () => {
    const { onLogError, restaurant } = this.props;
    this.setState({ loading: true });
    const { visitDate, visitTime, tables, ...commonProps } = this.state.values;
    const reserved_on = generateReservedOn({ visitDate, visitTime });
    let tableIds: number[] = [];
    if (this.props.config.flexible_tables) {
      if (!tables) {
        const errorHandler = onLogError || console.error;
        errorHandler(
          `flexible_tables is true, but tables are not provided for ${restaurant.name} (${restaurant.id})`,
          {
            visitDate,
            visitTime,
            restaurantId: restaurant.id,
            restaurantName: restaurant.name,
          }
        );
      } else {
        tableIds = tables.map((table) =>
          typeof table === 'object' ? table.id : table
        );
      }
    }
    const flexibleTablesProps = { tables: tableIds };
    const payload: Partial<ParamsReservationEdit> = {
      ...commonProps,
      reserved_on,
      ...flexibleTablesProps,
    };
    this.props
      .onSave(this.props.reservation, payload)
      .then(() => this.props.onClose())
      .finally(() => this.setState({ loading: false }));
  };

  cancelEdit = () =>
    this.setState({
      editing: false,
      values: {
        ...convertSavedReservationToNormalized(
          this.props.reservation,
          this.props.config
        ),
      },
    });

  cancelBooking = () => {
    this.setState({ loading: true });
    this.props.onCancel(this.props.reservation);
  };
  reportNoShow = () => {
    this.setState({ loading: true });
    this.props.reportNoShow(this.props.reservation);
  };
  markAsPresent = () => {
    this.setState({ loading: true });
    this.props.onSave(this.props.reservation, { extras: { present: true } });
  };

  updateField = (key, value) =>
    this.setState({
      values: {
        ...this.state.values,
        [key]: value,
      },
    });

  updateTables = (newTables: ITable[]) => this.updateField('tables', newTables);

  getNonVaccinatedLabel = () => {
    const { reservation } = this.props;
    const amount = reservation?.extras?.non_vaccinated_amount;
    if (amount === 0 || amount === '0') {
      return '0 (wszyscy zaszczepieni)';
    }

    return !!amount ? `${amount} z ${reservation.guests_number}` : 'Nie podano';
  };

  updateExtrasByKey = (key: string, values: TEditReservationValues) => (e) => {
    this.setState({
      values: {
        ...values,
        extras: {
          ...values.extras,
          [key]: e.target.value,
        },
      },
    });
  };

  addAttachment = (savedFile: ISavedFile) => {
    const newAttachments: ISavedFile[] = [
      ...(this.props.reservation.attachments || []),
      savedFile,
    ];
    this.updateField('attachments', newAttachments);
  };

  deleteAttachment = (deletedIndex: number) => {
    const attachments = this.props.reservation.attachments || [];
    const newAttachments: ISavedFile[] = attachments.map((item, index) =>
      deletedIndex === index
        ? {
            ...item,
            action: 'delete',
          }
        : item
    );
    this.updateField('attachments', newAttachments);
  };

  render() {
    const { editing } = this.state;
    return (
      <Modal
        isOpen={true}
        toggle={this.props.onClose}
        key="modal"
        className="reservation-modal"
      >
        <ModalHeader toggle={this.props.onClose} className="pt-1">
          Rezerwacja #{this.props.reservation.id}
        </ModalHeader>
        <ModalBody>
          {editing ? this.renderInputs() : this.renderStaticValues()}
          {this.props.children}
        </ModalBody>
        <ModalFooter>{this.renderFooterButtons()}</ModalFooter>
      </Modal>
    );
  }

  renderInputs() {
    const { config, restaurant } = this.props;
    const { values } = this.state;
    return (
      <>
        {getBaseModalFields().map(this.renderInputField)}
        {Restaurant.wantsToCountNonVaccinated(restaurant) &&
          this.renderNonVaccinatedField()}
        {hasKids(config) && this.renderKidsNumberField()}
        {canSelectDuration(config) && this.renderDurationSelect()}
        {config.flexible_tables && this.renderTablesSelect()}
        {hasAssignee(config) && this.renderAssigneeSelect()}
        <FormGroup key="notes_from_restaurant">
          <Label for="notes_from_restaurant">
            {i18next.t('reservation.notes_from_restaurant')}
          </Label>
          <Input
            type="textarea"
            name="notes_from_restaurant"
            id="notes_from_restaurant"
            onChange={(e) =>
              this.updateField('notes_from_restaurant', e.target.value)
            }
            value={values.notes_from_restaurant || ''}
          />
        </FormGroup>
        <FormGroup check>
          <Input
            type="checkbox"
            name="is_entire_place"
            id="is_entire_place"
            style={checkBoxStyle}
            onChange={(e) =>
              this.updateField('is_entire_place', e.target.checked)
            }
            checked={values.is_entire_place}
          />
          <Label for="is_entire_place">
            {i18next.t('book-modal.is_entire_place')}
          </Label>
        </FormGroup>
        <ErrorBoundary
          componentName="ReservationAttachmentsInputs"
          errorComponent={
            <OtoError className={'mt-2'} type={'reservation-attachments'} />
          }
        >
          <ReservationAttachments
            attachments={values.attachments || null}
            onAdd={this.addAttachment}
            onDelete={this.deleteAttachment}
            renderFileUpload={this.props.renderFileUpload}
          />
        </ErrorBoundary>
      </>
    );
  }

  renderInputField = (field: IField) => {
    const { values } = this.state;
    const value = values[field.key] || '';
    const isValid = field.isValid ? field.isValid(value) : !!value;
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
      this.updateField(field.key, e.target.value);

    if (field.showOnlyWhenValuePresent && !value) {
      return null;
    }

    if (field.type === 'time') {
      return (
        <FormGroup key={field.key}>
          <Label for={field.key}>{field.label}</Label>
          {isIOS() || isSafari() ? (
            <Input
              type="time"
              name="visitTime"
              id="visitTime"
              onChange={onChange}
              value={value}
              className="mw-125"
            />
          ) : (
            <TimePicker
              className="d-flex flex-row align-items-center"
              defaultHour={value.slice(0, 2)}
              defaultMin={value.slice(3)}
              onChange={(newVisitTime: string) =>
                this.updateField(field.key, newVisitTime)
              }
            />
          )}
        </FormGroup>
      );
    }
    return (
      <FormGroup key={field.key}>
        <Label for={field.key}>{field.label}</Label>
        {
          <Input
            type={field.type || 'text'}
            name={field.key}
            id={field.key}
            onChange={onChange}
            value={value}
            className={field.inputClassName || ''}
            style={isValid ? {} : { border: '1px solid red' }}
          />
        }
      </FormGroup>
    );
  };

  renderNonVaccinatedField = () => {
    const { values } = this.state;
    const key = 'non_vaccinated_amount';
    return (
      <FormGroup key={key}>
        <Label for={key}>Liczba osób niezaszczepionych</Label>
        {
          <Input
            type="number"
            name={key}
            id={key}
            onChange={this.updateExtrasByKey(key, values)}
            value={values.extras?.[key] || ''}
          />
        }
      </FormGroup>
    );
  };

  renderKidsNumberField = () => {
    const { values } = this.state;
    const key = 'kids_number';
    return (
      <FormGroup key={key}>
        <Label for={key}>W tym dzieci siedzących</Label>
        {
          <Input
            type="number"
            name={key}
            id={key}
            onChange={this.updateExtrasByKey(key, values)}
            value={values.extras?.[key] || ''}
          />
        }
      </FormGroup>
    );
  };

  renderDurationSelect() {
    const { config } = this.props;
    const { values } = this.state;
    const key = 'duration';
    const handleDurationChange = (e) => {
      this.setState({
        values: {
          ...values,
          [key]: e.target.value,
        },
      });
    };
    return (
      <FormGroup>
        <Label for={key}>{i18next.t('reservation.duration')}</Label>
        <Input
          type="select"
          name={key}
          id={key}
          onChange={handleDurationChange}
          value={values[key]}
          className="mw-200"
        >
          {getDurationOptions(config).map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  }

  renderTablesSelect() {
    const {
      loadTableSuggestions,
      restaurant,
      suggestedTables,
      tables,
      tableSuggestionsLoading,
    } = this.props;
    const { values } = this.state;

    if (!tables) {
      return <div>Brak stolików</div>;
    }

    return (
      <FormGroup>
        <div className="row d-flex flex-column">
          <AddReservationTablesSelect
            allTables={tables}
            classNameTablesField="col-12"
            classNameTablesAvailabilityField="col-12"
            disabled={false}
            onTablesSelected={this.updateTables}
            loadTableSuggestions={loadTableSuggestions}
            restaurant={restaurant}
            values={values}
            selectedTables={(values.tables || []).map((t) =>
              addPeopleAmountToTableTitle(t, {
                addPlace: false,
                allTables: tables,
              })
            )}
            suggestedTables={suggestedTables}
            tableSuggestionsLoading={tableSuggestionsLoading}
          />
        </div>
      </FormGroup>
    );
  }

  renderAssigneeSelect() {
    const { config } = this.props;
    const { values } = this.state;
    const { assignee_list } = config;
    const { extras } = values;
    const key = 'assignee';
    return (
      <FormGroup>
        <Label for={key}>{i18next.t('reservation.assignee')}</Label>
        <Input
          type="select"
          name={key}
          id={key}
          onChange={this.updateExtrasByKey(key, values)}
          value={extras?.assignee || ''}
        >
          <option key="" value="">
            {i18next.t('Unset')}
          </option>
          {(assignee_list || []).map((opt) => (
            <option key={opt} value={opt}>
              {opt}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  }

  renderStaticValues() {
    const { config, reservation, restaurant } = this.props;
    return (
      <>
        {getBaseModalFields().map(this.renderFieldLabel)}
        {this.renderFieldLabel(getCreatedAtStaticField())}
        {Restaurant.wantsToCountNonVaccinated(restaurant) && (
          <div>
            Liczba osób niezaszczepionych: {this.getNonVaccinatedLabel()}
          </div>
        )}
        {hasKids(config) && (
          <div>
            {i18next.t('reservation.includes_children')}:{' '}
            {getKidsNumber(reservation)}
          </div>
        )}
        <hr className="mt-2" />
        {canSelectDuration(config) && (
          <div>
            {i18next.t('reservation.duration')}:{' '}
            {
              possibleReservationDurations.find(
                (r) => r.value === reservation.duration
              )?.label
            }{' '}
          </div>
        )}
        {this.renderSelectedTables()}
        <hr className="mt-2" />
        {hasAssignee(config) ? (
          <div>
            {i18next.t('reservation.assignee')}:{' '}
            {reservation.extras?.assignee || i18next.t('Unset')}
          </div>
        ) : null}
        <div>
          {i18next.t('reservation.notes_from_guest')}:{' '}
          {reservation.notes_from_guest || i18next.t('book-modal.no_notes')}
        </div>
        <div>
          {i18next.t('reservation.notes_from_restaurant')}:{' '}
          {reservation.notes_from_restaurant ||
            i18next.t('book-modal.no_notes')}
        </div>
        <div>
          {i18next.t('book-modal.is_entire_place')}:{' '}
          {reservation['is_entire_place'] ? i18next.t('Yes') : i18next.t('No')}
        </div>
        {this.renderDeclinedReason()}
        <ErrorBoundary
          componentName="ReservationAttachmentsStaticValues"
          errorComponent={
            <OtoError className={'mt-2'} type={'reservation-attachments'} />
          }
        >
          <ReservationAttachments
            attachments={this.props.reservation.attachments || null}
            canEdit={false}
            onAdd={this.addAttachment}
            onDelete={this.deleteAttachment}
            renderFileUpload={this.props.renderFileUpload}
          />
        </ErrorBoundary>
      </>
    );
  }

  renderFieldLabel = (field: IField) => {
    // TODO fixme using values instead of reservation because reservation has reserved_on not splitted
    const { values } = this.state;
    const value = values[field.key];
    if (field.showOnlyWhenValuePresent && !value) {
      return null;
    }

    return (
      <div key={field.key} className={field.staticClassName || ''}>
        {field.label}: {value || field.default}
      </div>
    );
  };

  renderSelectedTables = () => {
    const { config, reservation } = this.props;
    if (!config.flexible_tables) {
      return null;
    }

    return (
      <div>
        {i18next.t('reservation.tables')}:{' '}
        <TablesFormatted showPlaceName tables={reservation.tables || []} />
      </div>
    );
  };

  renderDeclinedReason() {
    const reservation = this.props.reservation;
    if (!isDeclined(reservation)) {
      return null;
    }
    return (
      <div>
        {i18next.t('book-modal.decline_reason')}:{' '}
        {mapLabelsToDeclineResons[reservation.declined_reason_id as number]}
      </div>
    );
  }

  renderFooterButtons = () => {
    const { editing, loading } = this.state;
    if (loading) {
      return <OtoSpinner center />;
    }
    if (editing) {
      return (
        <>
          <Button color="success" onClick={this.saveEdit}>
            {i18next.t('Save')}
          </Button>{' '}
          <Button color="primary" onClick={this.cancelEdit}>
            {i18next.t('book-modal.cancel_edit')}
          </Button>{' '}
        </>
      );
    }
    return (
      <>
        <Button color="primary" onClick={this.edit}>
          {i18next.t('Edit')}
        </Button>{' '}
        <Button color="success" onClick={this.markAsPresent}>
          {i18next.t('book-modal.guest_present')}
        </Button>{' '}
        <Button color="danger" onClick={this.cancelBooking}>
          {i18next.t('book-modal.cancel_book')}
        </Button>{' '}
        <Button color="danger" onClick={this.reportNoShow}>
          {i18next.t('book-modal.report_no_show')}
        </Button>
      </>
    );
  };
}

export { ReservationsModal };
