import { OriginalRestaurant } from 'types/restaurants';
import { TUserRestaurant, User } from 'types/user';
import Logger from 'utils/log';
import APIService from 'services/api';
import { IAppState } from './main';
import { Tenants } from './tenants';
import { EAsyncStatus } from 'enums';
import { AddUserFormValues } from 'app/components/users/AddUserForm';
import { toast } from 'react-toastify';
import { AddUserRestaurantFormValues } from 'app/components/users/AddUserRestaurantForm';
import { AddRestaurantFormValues } from 'app/components/restaurants/AddRestaurantForm';
import { getArrayWithUpdatedObject } from 'utils/array';
import { TRestaurantFinancialConfig, TRestaurantFinancialConfigOrders } from 'types';
import { store } from 'store';

export interface IAdminReducerState {
  restaurants: OriginalRestaurant[];
  restaurantsState: EAsyncStatus;
  restaurantsUpdating: number[];
  restaurantsLoadedAt: number | null;
  users: User[];
  usersState: EAsyncStatus;
  usersRestaurants: TUserRestaurant[];
  usersRestaurantsState: EAsyncStatus;
  tenant: Tenants;
}

const DEFAULT_STATE: IAdminReducerState = {
  restaurants: [],
  restaurantsState: EAsyncStatus.IDLE,
  restaurantsLoadedAt: null,
  restaurantsUpdating: [],
  users: [],
  usersState: EAsyncStatus.IDLE,
  usersRestaurants: [],
  usersRestaurantsState: EAsyncStatus.IDLE,
  tenant: Tenants.OtoStolik,
};

const ADMIN_SET_RESTAURANTS_SUCCESS = 'ADMIN/SET_RESTAURANTS_SUCCESS';
const ADMIN_SET_RESTAURANTS_LOADING = 'ADMIN/SET_RESTAURANTS_LOADING';
const ADMIN_ADD_RESTAURANT = 'ADMIN/ADMIN_ADD_RESTAURANT';

const ADMIN_UPDATE_RESTAURANT_REQUESTED =
  'ADMIN/ADMIN_UPDATE_RESTAURANT_REQUESTED';
const ADMIN_UPDATE_RESTAURANT_SUCCEEDED =
  'ADMIN/ADMIN_UPDATE_RESTAURANT_SUCCEEDED';
const ADMIN_UPDATE_RESTAURANT_FAILED = 'ADMIN/ADMIN_UPDATE_RESTAURANT_FAILED';

const ADMIN_SET_USERS_SUCCESS = 'ADMIN/ADMIN_SET_USERS_SUCCESS';
const ADMIN_SET_USERS_LOADING = 'ADMIN/ADMIN_SET_USERS_LOADING';
const ADMIN_ADD_USER = 'ADMIN/ADMIN_ADD_USER';

const ADMIN_SET_USERS_RESTAURANTS_SUCCESS =
  'ADMIN/SET_USERS_RESTAURANTS_SUCCESS';
const ADMIN_SET_USERS_RESTAURANTS_LOADING =
  'ADMIN/SET_USERS_RESTAURANTS_LOADING';
const ADMIN_ADD_USER_RESTAURANT = 'ADMIN/ADMIN_ADD_USER_RESTAURANT';

const ADMIN_SET_TENANT = 'ADMIN/SET_TENANT';

interface IAdminAction {
  type: string;
  payload: any;
}

export default function adminReducer(
  state = DEFAULT_STATE,
  action: IAdminAction
): IAdminReducerState {
  switch (action.type) {
    case ADMIN_SET_RESTAURANTS_LOADING: {
      return {
        ...state,
        restaurants: [],
        restaurantsState: EAsyncStatus.PENDING,
        restaurantsLoadedAt: null,
      };
    }
    case ADMIN_SET_RESTAURANTS_SUCCESS:
      return {
        ...state,
        restaurants: action.payload,
        restaurantsState: EAsyncStatus.RESOLVED,
        restaurantsLoadedAt: Date.now(),
      };
    case ADMIN_ADD_RESTAURANT:
      return {
        ...state,
        restaurants: [...state.restaurants, action.payload],
      };
    case ADMIN_UPDATE_RESTAURANT_REQUESTED: {
      return {
        ...state,
        restaurantsUpdating: [...state.restaurantsUpdating, action.payload],
      };
    }
    case ADMIN_UPDATE_RESTAURANT_SUCCEEDED: {
      return {
        ...state,
        restaurants: getArrayWithUpdatedObject(
          state.restaurants,
          action.payload
        ),
        restaurantsUpdating: state.restaurantsUpdating.filter(
          (id) => id !== action.payload.id
        ),
      };
    }
    case ADMIN_UPDATE_RESTAURANT_FAILED: {
      return {
        ...state,
        restaurantsUpdating: state.restaurantsUpdating.filter(
          (id) => id !== action.payload.id
        ),
      };
    }
    case ADMIN_SET_USERS_LOADING: {
      return {
        ...state,
        users: [],
        usersState: EAsyncStatus.PENDING,
      };
    }
    case ADMIN_SET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        usersState: EAsyncStatus.RESOLVED,
      };
    case ADMIN_ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
      };
    case ADMIN_SET_USERS_RESTAURANTS_LOADING: {
      return {
        ...state,
        usersRestaurants: [],
        usersRestaurantsState: EAsyncStatus.PENDING,
      };
    }
    case ADMIN_SET_USERS_RESTAURANTS_SUCCESS:
      return {
        ...state,
        usersRestaurants: action.payload,
        usersRestaurantsState: EAsyncStatus.RESOLVED,
      };
    case ADMIN_ADD_USER_RESTAURANT:
      return {
        ...state,
        usersRestaurants: [...state.usersRestaurants, action.payload],
      };
    case ADMIN_SET_TENANT:
      return {
        ...state,
        tenant: action.payload,
      };
    default:
      return state;
  }
}

export const setAdminRestaurants = (
  restaurants: OriginalRestaurant[]
): IAdminAction => ({
  type: ADMIN_SET_RESTAURANTS_SUCCESS,
  payload: restaurants,
});

export const loadAdminRestaurants = () => (dispatch) => {
  dispatch({ type: ADMIN_SET_RESTAURANTS_LOADING });

  const url = '/admin/restaurants'
  return APIService.get(url)
    .then((restaurants) => {
      dispatch(setAdminRestaurants(restaurants));
      return restaurants;
    })
    .catch((e) => {
      Logger.fetchError({ e, url });
    });
};

export const createRestaurant =
  (values: AddRestaurantFormValues) => (dispatch) => {
    const url = `/restaurants`;
    return APIService.post(url, values)
      .then((restaurant) => {
        dispatch({
          type: ADMIN_ADD_RESTAURANT,
          payload: restaurant,
        });
        toast.success('Restauracja została utworzona');
        return restaurant;
      })
      .catch((e) => {
        Logger.fetchError({ e, url });
        toast.error('Wystąpił błąd podczas tworzenia restauracji');
        return e;
      });
  };

export const updateRestaurant =
  (restaurant: OriginalRestaurant, payload: Partial<OriginalRestaurant>) =>
  (dispatch) => {
    const url = Object.keys(payload).includes('status')
      ? `/restaurants/${restaurant.id}/changeStatus`
      : `/restaurants/${restaurant.id}`;

    dispatch({
      type: ADMIN_UPDATE_RESTAURANT_REQUESTED,
      payload: restaurant.id,
    });

    return APIService.put(url, payload)
      .then((updatedRestaurant) => {
        toast.success(`Restauracja ${restaurant.name} zaktualizowana`);
        dispatch({
          type: ADMIN_UPDATE_RESTAURANT_SUCCEEDED,
          payload: updatedRestaurant,
        });
      })
      .catch((e) => {
        toast.error(`Problem aktualizacji restauracji ${restaurant.name}`);
        Logger.fetchError({ e, url });
        dispatch({
          type: ADMIN_UPDATE_RESTAURANT_FAILED,
          payload: restaurant,
        });
      });
  };

export const updateRestaurantFinancialSettingsOrders =
  (currentConfig: TRestaurantFinancialConfig, payload: TRestaurantFinancialConfigOrders) => (dispatch) => {
    const restaurant = store.getState().admin.restaurants.find(
      (r) => r.id === currentConfig.restaurant_id
    );

    const url = `/restaurants/${currentConfig.restaurant_id}/financial-settings/${currentConfig.id}/orders`;

    dispatch({
      type: ADMIN_UPDATE_RESTAURANT_REQUESTED,
      payload: currentConfig.restaurant_id,
    });

    return APIService.put(url, payload)
      .then((updatedRestaurantFinancialConfig) => {
        toast.success('Ustawienia finansowe zaktualizowane');
        dispatch({
          type: ADMIN_UPDATE_RESTAURANT_SUCCEEDED,
          payload: {
            ...restaurant,
            finance_settings: updatedRestaurantFinancialConfig,
          },
        });
      })
      .catch((e) => {
        toast.error('Problem z aktualizacją ustawień finansowych');
        Logger.fetchError({ e, url });
        dispatch({
          type: ADMIN_UPDATE_RESTAURANT_FAILED,
          payload: restaurant,
        });
      });
  };

export const loadAdminUsers = () => (dispatch) => {
  dispatch({ type: ADMIN_SET_USERS_LOADING });
  const url = `/admin/users`;
  return APIService.get(url)
    .then((users) => {
      dispatch({
        type: ADMIN_SET_USERS_SUCCESS,
        payload: users,
      });
      return users;
    })
    .catch((e) => {
      Logger.fetchError({ e, url });
    });
};

export const createUser = (values: AddUserFormValues) => (dispatch) => {
  const url = `/users`;
  return APIService.post(url, values)
    .then((user) => {
      dispatch({
        type: ADMIN_ADD_USER,
        payload: user,
      });
      toast.success('Użytkownik został utworzony');
      return user;
    })
    .catch((e) => {
      Logger.fetchError({ e, url });
      toast.error('Wystąpił błąd podczas tworzenia użytkownika');
    });
};

export const loadAdminUsersRestaurants = () => (dispatch) => {
  dispatch({ type: ADMIN_SET_USERS_RESTAURANTS_LOADING });
  const url = '/admin/users-restaurants';
  return APIService.get(url)
    .then((usersRestaurants) => {
      dispatch({
        type: ADMIN_SET_USERS_RESTAURANTS_SUCCESS,
        payload: usersRestaurants,
      });
      return usersRestaurants;
    })
    .catch((e) => {
      Logger.fetchError({ e, url });
    });
};

export const createUserRestaurant =
  (values: AddUserRestaurantFormValues) => (dispatch) => {
    const url = `/admin/users-restaurants`;
    return APIService.post(url, values)
      .then((userRestaurant) => {
        dispatch({
          type: ADMIN_ADD_USER_RESTAURANT,
          payload: userRestaurant,
        });
        toast.success('Użytkownik został przypisany do restauracji');
        return userRestaurant;
      })
      .catch((e) => {
        Logger.fetchError({ e, url });
        toast.error(
          'Wystąpił błąd podczas przypisywania użytkownika do restaruacji'
        );
      });
  };

export const setAdminTenant = (tenant: Tenants) => ({
  type: ADMIN_SET_TENANT,
  payload: tenant,
});

// SELECTORS

export const selectAdminRestaurants = (store: IAppState) =>
  store.admin.restaurants;
export const selectAdminRestaurantsLoading = (store: IAppState) =>
  store.admin.restaurantsState === EAsyncStatus.PENDING;
export const selectAdminRestaurantsLoadedAt = (store: IAppState) =>
  store.admin.restaurantsLoadedAt;
export const selectAdminRestaurantsUpdating = (store: IAppState) =>
  store.admin.restaurantsUpdating;

export const selectAdminUsers = (store: IAppState) => store.admin.users;
export const selectAdminUsersLoading = (store: IAppState) =>
  store.admin.usersState === EAsyncStatus.PENDING;

export const selectAdminUsersRestaurants = (store: IAppState) =>
  store.admin.usersRestaurants;
export const selectAdminUsersRestaurantsLoading = (store: IAppState) =>
  store.admin.usersRestaurantsState === EAsyncStatus.PENDING;

export const selectAdminTenant = (store: IAppState) => store.admin.tenant;
