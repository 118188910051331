import type { TReservationCustomer } from 'types';
import { convertToCSV, downloadFile } from './helpers';

export const downloadReservationCustomersCSV = (
  customers: TReservationCustomer[],
  fileName: string
): void => {
  const csvContent = generateReservationCustomersCSV(customers);
  downloadFile({
    content: csvContent,
    fileName,
    type: 'text/csv;charset=utf-8;',
  });
};

function generateReservationCustomersCSV(
  customers: TReservationCustomer[]
): string {
  const headers = [
    'Imię Nazwisko',
    'Nr telefonu',
    'Liczba rezerwacji',
    'Liczba gości',
    'Data pierwszej rezerwacji',
    'Data ostatniej rezerwacji',
  ];

  const rows = customers.map((customer) => {
    return [
      customer.customer_name,
      customer.phone_number,
      customer.reservation_count,
      customer.guests_number,
      new Date(customer.min_reserved_on),
      new Date(customer.max_reserved_on),
    ];
  });

  return convertToCSV({
    headers,
    rows,
    dateFormat: 'yyyy-MM-dd',
  });
}
