import React from 'react';
import { Button, Card, CardHeader, CardBody, Col, Row } from 'reactstrap';
import i18next from 'i18next';
import { Multiselect } from 'multiselect-react-dropdown';
import { MapPin, Phone } from 'react-feather';

import PreviewLink from 'app/views/ViewPreviewLink';
import { OtoButtons, OtoInlineInput } from 'app/components/common';
import { SIDEBAR_ORDER_CUISINE_LIST } from 'app/components/settings/cuisines';
import { YesNoIcons } from 'app/components/icons/IconSets';
import type { TSetModalFunction } from 'app/containers/WithSetModal';
import EditArea from './EditArea';
import AdminRestaurantListItemProducts from './AdminRestaurantListItemProducts';
import type { IAdminVisibilityFilters } from './VisibilityToggles';
import RestaurantFinancialSettings from './RestaurantFinancialSettings';
import type {
  OriginalRestaurant,
  TOriginalRestaurantWithFinancialConfig,
  TRestaurantFinancialConfigOrders,
} from 'types';
import { ModalTypes } from 'config';
import { Tenants } from 'enums';

const isLive = (restaurant: OriginalRestaurant) => restaurant.status === 'live';

interface AdminRestaurantListItemProps {
  availableCuisineTypes: string[];
  addProduct: (restaurant: OriginalRestaurant, product: string) => void;
  fieldsVisibility: IAdminVisibilityFilters;
  isLoading: boolean;
  restaurant: OriginalRestaurant;
  productsToShow: string[];
  renderMakeTestPhoneCall?: (restaurant: OriginalRestaurant) => React.ReactNode;
  removeProduct: (restaurant: OriginalRestaurant, product: string) => void;
  setModal: TSetModalFunction;
  onUpdateRestaurant: (
    restaurant: OriginalRestaurant,
    payload: Partial<OriginalRestaurant>
  ) => void;
  onRestaurantFinancialSettingsUpdate: (
    restaurant: TOriginalRestaurantWithFinancialConfig,
    payload: TRestaurantFinancialConfigOrders
  ) => void;
}

const AdminRestaurantListItem: React.FC<AdminRestaurantListItemProps> = ({
  availableCuisineTypes,
  addProduct,
  fieldsVisibility,
  isLoading,
  productsToShow,
  renderMakeTestPhoneCall,
  restaurant,
  removeProduct,
  setModal,
  onRestaurantFinancialSettingsUpdate,
  onUpdateRestaurant,
}) => {
  const {
    showContactDetails,
    showCuisineLabels,
    showCuisineType,
    showEditArea,
    showFinancialSettings,
    showImages,
  } = fieldsVisibility;

  const handleEditOrdersCommission = (
    tenantName: Tenants,
    restaurant: TOriginalRestaurantWithFinancialConfig
  ) => {
    const currentCommissionForTenant =
      restaurant.finance_settings?.orders?.commission?.[tenantName];
    setModal(
      {
        title: 'Podaj nową wysokość podstawowej prowizji od zamówień',
        text: (
          <>
            <div>Restauracja: {restaurant.name}</div>
            <div>Platforma: {tenantName}</div>
            <div>Obecna prowizja: {currentCommissionForTenant?.orders}%</div>
          </>
        ),
        confirm: (newCommission: number) => {
          onRestaurantFinancialSettingsUpdate(restaurant, {
            commission: {
              [tenantName]: {
                ...currentCommissionForTenant,
                orders: newCommission,
              },
            },
          });
        },
        confirmColor: 'info',
        confirmText: i18next.t('Save'),
      },
      ModalTypes.INPUT
    );
  };

  const handleEditOnlinePaymentsCommission = (
    tenantName: Tenants,
    restaurant: TOriginalRestaurantWithFinancialConfig
  ) => {
    const currentCommissionForTenant =
      restaurant.finance_settings?.orders?.commission?.[tenantName];
    setModal(
      {
        title: 'Podaj nową wysokość prowizji od płatności online',
        text: (
          <>
            <div>Restauracja: {restaurant.name}</div>
            <div>Platforma: {tenantName}</div>
            <div>
              Obecna prowizja: {currentCommissionForTenant?.online_payments}%
            </div>
          </>
        ),
        confirm: (newCommission: number) => {
          onRestaurantFinancialSettingsUpdate(restaurant, {
            commission: {
              [tenantName]: {
                ...currentCommissionForTenant,
                online_payments: newCommission,
              },
            },
          });
        },
        confirmColor: 'info',
        confirmText: i18next.t('Save'),
      },
      ModalTypes.INPUT
    );
  };

  return (
    <Card tag="article" key={restaurant.id}>
      <CardHeader className="d-flex flex-row">
        <h3 className="mb-0">
          {restaurant.name} (#{restaurant.id})
        </h3>
        <PreviewLink restaurant={restaurant} />
      </CardHeader>
      <hr />
      <CardBody>
        <Row>
          <AdminRestaurantListItemStatus
            restaurant={restaurant}
            onUpdateRestaurant={onUpdateRestaurant}
          />
          {showEditArea && (
            <Col xs="12" md="4">
              <EditArea
                restaurant={restaurant}
                onUpdateRestaurant={onUpdateRestaurant}
              />
            </Col>
          )}
          {showContactDetails && (
            <>
              {renderContactDetailsCell(restaurant, onUpdateRestaurant)}
              {renderMakeTestPhoneCall?.(restaurant)}
            </>
          )}
          {showCuisineType &&
            renderCuisineTypeCell(
              restaurant,
              availableCuisineTypes,
              setModal,
              onUpdateRestaurant
            )}
          {showImages &&
            renderImagePathCell(restaurant, setModal, onUpdateRestaurant)}
        </Row>
        {showCuisineLabels && (
          <Row className="my-3">
            {renderCuisineLabelCell(restaurant, onUpdateRestaurant)}
          </Row>
        )}
        <Row>
          <Col xs="12">
            <AdminRestaurantListItemProducts
              addProduct={addProduct}
              isLoading={isLoading}
              productsToShow={productsToShow}
              removeProduct={removeProduct}
              restaurant={restaurant}
            />
          </Col>
        </Row>

        {showFinancialSettings && (
          <Row>
            <Col xs="12">
              <RestaurantFinancialSettings
                onEditOrdersCommissionClick={(tenantName) =>
                  handleEditOrdersCommission(
                    tenantName,
                    restaurant as TOriginalRestaurantWithFinancialConfig
                  )
                }
                onEditOnlinePaymentsCommissionClick={(tenantName) =>
                  handleEditOnlinePaymentsCommission(
                    tenantName,
                    restaurant as TOriginalRestaurantWithFinancialConfig
                  )
                }
                restaurant={restaurant}
              />
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

AdminRestaurantListItem.displayName = 'AdminRestaurantListItem';

function renderContactDetailsCell(
  restaurant: OriginalRestaurant,
  onUpdateRestaurant: (
    restaurant: OriginalRestaurant,
    payload: Partial<OriginalRestaurant>
  ) => void
) {
  return (
    <Col xs="12" md="4">
      <div className="align-vertical mb-2 gap-10">
        <Phone />
        <OtoInlineInput
          label={restaurant.phone}
          initialValue={restaurant.phone}
          onConfirm={(phone) =>
            onUpdateRestaurant(restaurant, {
              phone,
            })
          }
          showConfirmIcon
        />
      </div>
      <div className="align-vertical mb-2 gap-10">
        <MapPin />
        <OtoInlineInput
          label={restaurant.location}
          initialValue={restaurant.location}
          onConfirm={(location) =>
            onUpdateRestaurant(restaurant, {
              location,
            })
          }
          showConfirmIcon
        />
      </div>
    </Col>
  );
}

function renderCuisineLabelCell(
  restaurant: OriginalRestaurant,
  onUpdateRestaurant: (
    restaurant: OriginalRestaurant,
    payload: Partial<OriginalRestaurant>
  ) => void
) {
  const labels = restaurant.cuisine_label || [];
  const handleUpdate = (selectedLabels) =>
    onUpdateRestaurant(restaurant, {
      cuisine_label: selectedLabels,
    });
  return (
    <Col xs="12" md="6">
      {labels.join(', ')}
      <Multiselect
        isObject={false}
        options={SIDEBAR_ORDER_CUISINE_LIST}
        selectedValues={labels}
        onSelect={handleUpdate}
        onRemove={handleUpdate}
        placeholder="Typu kuchni (max. 5)"
      />
    </Col>
  );
}

function renderCuisineTypeCell(
  restaurant: OriginalRestaurant,
  availableCuisineTypes: string[],
  setModal: TSetModalFunction,
  onUpdateRestaurant: (
    restaurant: OriginalRestaurant,
    payload: Partial<OriginalRestaurant>
  ) => void
) {
  return (
    <Col xs="12" md="4">
      <span>{restaurant.cuisine_type || 'Brak cuisine_type'}</span>
      <OtoButtons.EditButton
        onClick={() =>
          setModal(
            {
              title: 'Wybierz lub wpisz nowy typ kuchni',
              confirm: (
                newCuisineType?: string,
                newCustomCuisineType?: string
              ) =>
                onUpdateRestaurant(restaurant, {
                  cuisine_type: newCustomCuisineType || newCuisineType,
                }),
              options: availableCuisineTypes,
              other: 'Wpisz ręcznie',
              initialValue: restaurant.cuisine_type,
              confirmColor: 'success',
            },
            ModalTypes.SELECT
          )
        }
      />
    </Col>
  );
}

function renderImagePathCell(
  restaurant: OriginalRestaurant,
  setModal: TSetModalFunction,
  onUpdateRestaurant: (
    restaurant: OriginalRestaurant,
    payload: Partial<OriginalRestaurant>
  ) => void
) {
  return (
    <Col xs="12" md="4">
      <span>
        {!!restaurant.images
          ? JSON.stringify(restaurant.images)
          : 'Brak obrazków'}
      </span>
      <OtoButtons.EditButton
        onClick={() =>
          setModal(
            {
              title: 'Podaj nowe nazwy zdjęć',
              confirm: (newImagePaths: string) =>
                onUpdateRestaurant(restaurant, {
                  images: JSON.parse(newImagePaths),
                }),
              initialValue: !!restaurant.images
                ? JSON.stringify(restaurant.images)
                : `[{"src": "food-cut-square-400/${restaurant.slug}/"}]`,
              confirmColor: 'success',
            },
            ModalTypes.INPUT
          )
        }
      />
      <div>
        {(restaurant.images || []).map((image) => (
          <img
            src={`https://cdn.otostolik.pl/img/${image.src}`}
            style={{ height: 100, minWidth: 70, marginRight: 10 }}
            alt=""
          />
        ))}
      </div>
    </Col>
  );
}

function AdminRestaurantListItemStatus({
  restaurant,
  onUpdateRestaurant,
}: {
  restaurant: OriginalRestaurant;
  onUpdateRestaurant: (
    restaurant: OriginalRestaurant,
    payload: Partial<OriginalRestaurant>
  ) => void;
}) {
  return (
    <Col xs="12" md="4">
      <div className="d-flex flex-row align-items-center mb-2">
        <div className="mr-2">
          <span className="font-weight-bold">Status:</span> {restaurant.status}
        </div>
        <Button
          color={isLive(restaurant) ? 'warning' : 'success'}
          onClick={() =>
            onUpdateRestaurant(restaurant, {
              status: isLive(restaurant) ? 'paused' : 'live',
            })
          }
          type="button"
        >
          {isLive(restaurant) ? 'Pause' : 'Set Live'}
        </Button>
      </div>
      {!!restaurant.config?.orders && (
        <div>
          {restaurant.config.orders.allowOrders === true ? (
            <>{YesNoIcons.YES} Zamówienia włączone</>
          ) : (
            <>
              {YesNoIcons.NO} Zamówienia wyłączone (data wyłączenia:{' '}
              {restaurant.config.orders.allowOrdersChangedAt})
            </>
          )}
        </div>
      )}
    </Col>
  );
}

export default AdminRestaurantListItem;
