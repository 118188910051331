import { SubmitButtonWithLoader } from 'app/components/common';
import { Field, Form, Formik, FormikErrors } from 'formik';
import React from 'react';
import {
  Col,
  FormGroup,
  FormText,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';

export type SendPushNotificationFormValues = {
  notificationType: string;
  title: string;
  text: string;
  amount: number;
  offset: number;
  test: boolean;
  allowAlreadySentDevices: boolean;
  allowInvalidDevices: boolean;
};

const initialValues: SendPushNotificationFormValues = {
  notificationType: '',
  title: 'Nowa restauracja w Twojej okolicy!',
  text: 'Sprawdź, co dla Ciebie przygotowaliśmy!',
  test: true,
  amount: 50,
  offset: 0,
  allowAlreadySentDevices: false,
  allowInvalidDevices: false,
};

type SendPushNotificationFormProps = {
  onSubmit: (values: SendPushNotificationFormValues) => void;
};

const validate = (values: SendPushNotificationFormValues) => {
  const errors: FormikErrors<SendPushNotificationFormValues> = {};
  ['notificationType', 'title', 'text', 'amount'].forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Pole wymagane';
    }
  });
  if (!/^[a-zA-Z0-9_-]*$/.test(values.notificationType)) {
    errors.notificationType = 'Nieprawidłowy format';
  }
  if (values.amount?.toString() !== '' && values.amount < 1) {
    errors.amount = 'Wartość musi być większa od 0';
  }
  return errors;
};

const SendPushNotificationForm: React.FC<SendPushNotificationFormProps> = ({
  onSubmit,
}) => {
  return (
    <Formik<SendPushNotificationFormValues>
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ errors, isSubmitting, isValid, touched }) => {
        const renderError = (fieldName: keyof SendPushNotificationFormValues) =>
          touched[fieldName] && errors[fieldName] ? (
            <FormFeedback>{errors[fieldName]}</FormFeedback>
          ) : null;
        return (
          <Form>
            <FormGroup>
              <Label for="notificationType">Typ powiadomienia</Label>
              <Field
                as={Input}
                className="mw-300"
                id="notificationType"
                name="notificationType"
                type="text"
                invalid={!!errors.notificationType}
              />
              {renderError('notificationType')}
              <FormText>
                Zapisz w formacie slug, bez spacji. Zacznij od nazwy appki,
                dodaj coś unikatowego, np. datę lub szczegóły (restauracja /
                nazwa eventu). Np. <b>lubje-nov-2024-ooh-mami-new-restaurant</b>
                , lub <b>lesznoje-oct-2024-lufa</b>
              </FormText>
            </FormGroup>
            <FormGroup className="mw-400">
              <Label for="title">Tytuł wiadomości</Label>
              <Field
                as={Input}
                id="title"
                name="title"
                type="text"
                invalid={!!errors.title}
              />
              {renderError('title')}
            </FormGroup>
            <FormGroup className="mw-500">
              <Label for="text">Treść wiadomości</Label>
              <Field
                as={Input}
                id="text"
                name="text"
                placeholder="Treść"
                type="textarea"
                invalid={!!errors.text}
              />
              {renderError('text')}
            </FormGroup>
            <Row>
              <Col xs="auto">
                <FormGroup>
                  <Label for="amount">Do ilu osób wysłać?</Label>
                  <Field
                    as={Input}
                    className="mw-150"
                    id="amount"
                    name="amount"
                    type="number"
                    step="1"
                    min="1"
                    invalid={!!errors.amount}
                  />
                  {renderError('amount')}
                </FormGroup>
              </Col>
              <Col xs="auto" className="mw-500">
                <FormGroup>
                  <Label for="offset">Ile osób pominąć (offset)</Label>
                  <Field
                    as={Input}
                    className="mw-150"
                    id="offset"
                    name="offset"
                    type="number"
                    step="1"
                    min="0"
                    invalid={!!errors.offset}
                  />
                  {renderError('offset')}
                  <FormText>
                    uzyteczne przy wysyłce, jeżeli zezwalamy na invalid devices
                    i wysyłamy częściami
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup check>
              <Label for="test">
                <Field as={Input} id="test" name="test" type="checkbox" />
                Test mode (pokaż szczegóły, ale nie wysyłaj - zalecane użycie
                przed wysyłką za każdym razem)
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label for="allowAlreadySentDevices">
                <Field
                  as={Input}
                  id="allowAlreadySentDevices"
                  name="allowAlreadySentDevices"
                  type="checkbox"
                />
                Allow already sent devices (pozwalaj wysłać drugi raz tej samej
                osobie)
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label for="allowInvalidDevices">
                <Field
                  as={Input}
                  id="allowInvalidDevices"
                  name="allowInvalidDevices"
                  type="checkbox"
                />
                Allow invalid devices (spróbuj wysłać do urządzeń, które nie
                mogą już nie być dostępne. Wiąże się z ryzykiem błędów na
                serwerze oraz z tym, że ktoś otrzyma ponad 1 wiadomość, bo nie
                będziemy wiedzieli o tym, że ją otrzymał za pierwszym razem)
              </Label>
            </FormGroup>
            <SubmitButtonWithLoader
              className="mt-2"
              disabled={!isValid}
              loading={isSubmitting}
              label={'Wyślij wiadomość'}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default SendPushNotificationForm;
