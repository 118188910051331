import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import OtoSpinner from '../common/OtoSpinner';
import { AddCompanyContactForm } from './AddCompanyContactForm';
import { ICompanyContact } from 'types/company';
import type {
  TAddCompanyContactModalProps,
  TCommonPropsForSpecificModals,
} from 'types';

type AddCompanyContactModalProps = TAddCompanyContactModalProps &
  TCommonPropsForSpecificModals<ICompanyContact>;

const AddCompanyContactModal: React.FC<AddCompanyContactModalProps> = (
  props
) => {
  const { companyId, closeModal, onSave } = props;

  return (
    <>
      <Modal isOpen>
        <ModalHeader toggle={closeModal}>{'Dodaj kontakt'}</ModalHeader>

        <AddCompanyContactForm companyId={companyId} handleSubmit={onSave}>
          {({ form, triggerSubmit, isDisabled, isLoading }) => (
            <>
              <ModalBody>{form}</ModalBody>
              <ModalFooter>
                {isLoading ? (
                  <OtoSpinner />
                ) : (
                  <>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={closeModal}
                    >
                      Zamknij
                    </Button>
                    <Button
                      type="button"
                      color="success"
                      onClick={triggerSubmit}
                      disabled={isDisabled}
                    >
                      Dodaj kontakt
                    </Button>
                  </>
                )}
              </ModalFooter>
            </>
          )}
        </AddCompanyContactForm>
      </Modal>
    </>
  );
};

AddCompanyContactModal.propTypes = {
  companyId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddCompanyContactModal;
