import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Tag } from 'react-feather';

import CommonSection from 'app/components/common/CommonSection';
import { PaymentOptionIcons } from 'app/components/icons/IconSets';
import { EPaymentType } from 'enums';
import { PageComponent } from 'app/types-global';

const ReferralsPage: PageComponent<{}> = () => {
  return (
    <CommonSection header="Poleć OtoStolik i zgarnij:">
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start mt-3">
        <Card className="mw-300 mb-0">
          <CardBody>
            <div className="text-primary text-center h3">
              <Tag />
            </div>
            miesiąc zamówień online bez prowizji dla Twojej restauracji
          </CardBody>
        </Card>
        <span className="h4 m-3 my-md-1 align-self-center">lub</span>
        <Card className="mw-300 mb-0">
          <CardBody>
            <div className="text-primary text-center h3">
              {PaymentOptionIcons[EPaymentType.CASH]}
            </div>
            100zł w postaci kuponu do wykorzystania na zamówienie z OtoStolik
          </CardBody>
        </Card>
      </div>
    </CommonSection>
  );
};

ReferralsPage.navName = 'Zarabiaj polecając';
ReferralsPage.url = '/referrals';

export default ReferralsPage;
