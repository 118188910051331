import React, { useCallback, useState } from 'react';
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import { toast } from 'react-toastify';

import type { PageComponent } from 'app/types-global';
import type { TCustomerWithSourceAndDevices } from 'types';
import UrlFetcher from 'app/containers/UrlFetcher';
import CustomersTable from 'app/components/customers/CustomersTable/CustomersTable';
import SendPushNotificationForm, {
  SendPushNotificationFormValues,
} from './SendPushNotificationForm';
import APIService from 'services/api';
import Logger from 'utils/log';
import useSetModal from 'hooks/useSetModal';
import { ModalTypes } from 'enums';

type SendPushParams = {
  appName: string;
  notificationType: string;
  title: string;
  text: string;
  test: boolean;
  allowAlreadySentDevices: boolean;
  allowInvalidDevices: boolean;
} & (
  | {
      customerId: number[];
    }
  | {
      amount: number;
      offset: number;
    }
);

export const AdminCustomersPage: PageComponent<{}> = () => {
  const availableMobileApps = ['lesznoje_mobile_app', 'lubje_mobile_app'];
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<number[]>([]);

  const [appName, setAppName] = useState<string>(availableMobileApps[0]);

  const toggleCustomerSelection = useCallback((customer: { id: number }) => {
    setSelectedCustomerIds((prev) =>
      prev.includes(customer.id)
        ? prev.filter((id) => id !== customer.id)
        : [...prev, customer.id]
    );
  }, []);

  const setModal = useSetModal();

  const handleSendPush = async (values: SendPushNotificationFormValues) => {
    const params: SendPushParams = selectedCustomerIds.length
      ? {
          ...values,
          appName,
          amount: undefined,
          offset: undefined,
          customerId: selectedCustomerIds,
        }
      : {
          ...values,
          appName,
          customerId: undefined,
        };
    const pushUrl = '/admin/notifications/push/marketing';

    const renderValue = (value: unknown) => {
      if (Array.isArray(value)) {
        return `lista (${value.length} elementów)`;
      }
      if (typeof value === 'object' && value) {
        return JSON.stringify(value);
      }
      if (typeof value === 'boolean') {
        return value ? 'Tak' : 'Nie';
      }
      return value;
    };

    const renderEntries = (data: Record<string, unknown>) =>
      Object.entries(data).map(([key, value]) => (
        <div key={key}>
          <strong>{key}:</strong> {renderValue(value)}
        </div>
      ));

    try {
      const response = await APIService.post(pushUrl, params);
      setModal(
        {
          title: params.test
            ? 'Testm mode - pogdląd przed wysyłką'
            : 'Powiadomienia wysłane',
          text: (
            <>
              <h3>Parametry wysyłki:</h3>
              <Card className={'my-2'}>
                <CardBody>
                  <div className={'text-danger'}>
                    Jeżeli wybierzesz przynajmniej jednego użytkownika z listy poniżej, to powiadomienia zostaną wysłane tylko do wybranych użytkowników.
                    <br />
                    W przeciwnym wypadku - zostaną wysłane do pierwszych użytkowników, na podstawie parametrów "amount" i "offset".
                  </div>
                </CardBody>
              </Card>
              {renderEntries(params)}
              <hr className="my-2" />
              <h3>Informacje zwrotne:</h3>
              {renderEntries(response)}
              <hr className="my-2" />
              <p>Jeżeli wszystko się zgadza - wyłącz test mode i działaj!</p>
            </>
          ),
          confirm: () => {},
          confirmText: 'OK',
          confirmColor: 'info',
        },
        ModalTypes.CONFIRM
      );
    } catch (error) {
      toast.error(
        `Wystąpił błąd podczas wysyłki pusha${
          error instanceof Error ? `: ${error.message}` : ''
        }`
      );
      Logger.fetchError({
        e: error,
        url: pushUrl,
        actionName: 'handleSendPush',
      });
    }
  };

  const url = `/admin/customers?app_name=${appName}`;
  return (
    <>
      <FormGroup>
        <Label for="appName">Aplikacja:</Label>
        <Input
          id="appName"
          name="appName"
          className="mw-250"
          type="select"
          value={appName}
          onChange={(e) => setAppName(e.target.value)}
        >
          {availableMobileApps.map((app) => (
            <option key={app} value={app}>
              {app}
            </option>
          ))}
        </Input>
      </FormGroup>
      <UrlFetcher<TCustomerWithSourceAndDevices[]> url={url}>
        {([customersWithDevices, urlFetcherProps]) => (
          <div>
            <div className="h3">Parametry pusha</div>
            <SendPushNotificationForm onSubmit={handleSendPush} />
            <hr className="my-4" />
            <div className="h3">
              Lista użytkowników aplikacji {appName} (
              {customersWithDevices.length} os.)
            </div>
            <CustomersTable
              customers={customersWithDevices}
              onCustomerSelect={toggleCustomerSelection}
              selectedCustomerIds={selectedCustomerIds}
            />
          </div>
        )}
      </UrlFetcher>
    </>
  );
};

AdminCustomersPage.url = '/admin/customers';
AdminCustomersPage.navName = 'admin.customers';

export default AdminCustomersPage;
