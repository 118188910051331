import { convertToCSV, downloadFile } from './helpers';
import type { ISavedReservation } from 'types';

export const downloadReservationsCSV = (
  reservations: ISavedReservation[],
  fileName: string
): void => {
  const csvContent = generateReservationsCSV(reservations);
  downloadFile({
    content: csvContent,
    fileName,
    type: 'text/csv;charset=utf-8;',
  });
};

function generateReservationsCSV(reservations: ISavedReservation[]): string {
  const headers = [
    'Imię Nazwisko',
    'Nr telefonu',
    'Email',
    'Data i godzina rezerwacji',
    'Liczba Gości',
    'Zgoda marketingowa na SMS',
    'Zgoda marketingowa na email',
  ];

  const rows = reservations.map((reservation) => {
    return [
      reservation.customer_name,
      reservation.phone_number,
      reservation.email,
      new Date(reservation.reserved_on),
      reservation.guests_number,
      !!reservation.sms_marketing,
      !!reservation.email_marketing,
    ];
  });

  return convertToCSV({
    headers,
    rows,
    dateFormat: 'yyyy-MM-dd',
  });
}
