import React from 'react';
import PropTypes from 'prop-types';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'reactstrap';
import { addDays } from 'date-fns';
import { FormikBag, FormikErrors, FormikProps, withFormik } from 'formik';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { toDateInputValue } from 'utils/date-time';
import { hasAssignee, hasFlexibleTables, hasKids } from 'utils/reservation';
import {
  addPeopleAmountToTableTitle,
  generateReservedOn,
  getDefaultDuration,
} from 'utils/reservation';
import OtoSpinner from 'app/components/common/OtoSpinner';

import Shapes from 'shapes/main';
import {
  AddReservationFormValues,
  CreateReservationDTO,
  IReservationExtras,
  ISavedFile,
  ITable,
  ITableWithPlace,
  NormalizedRestaurant,
  ReservationConfig,
  ReservationSource,
  TTableSuggestion,
  TTableSuggestionWithPeopleAmount,
  TTableWithPeopleAmount,
} from 'types';
import ReservationAttachments from 'app/components/reservations/ReservationAttachments';
import { IAppState } from 'store/main';
import { selectIsRootAdmin } from 'store/app';
import {
  findPlaceGoBack,
  loadTableSuggestions,
  showReservationProposalForMultipleTables,
  selectTableSuggestionsLoading,
  setSelectedTables,
  selectSelectedTables,
  resetFindPlace,
  findPlaceProceed,
  setReservationFormValues,
  selectCalendarPreferences,
  updateCalendarPreferences,
  selectTableSuggestions,
} from 'store/reservation-calendar';
import { addReservation } from 'store/reservations';
import AddReservationTablesSelect from 'app/components/reservations/AddReservationTablesSelect';
import AddReservationFormFull from './AddReservationFormFull';
import AddReservationFormStep1 from './AddReservationFormStep1';
import AddReservationFormStep2 from './AddReservationFormStep2';

import EntirePlaceReservationHelper from 'app/components/reservations/EntirePlaceReservationHelper';
import { OtoButtons } from 'app/components/common';
import SelectedTables from 'app/components/reservations/SelectedTables';
import FileUpload from 'app/components/common/FileUpload';

import './AddReservationPage.scss';

type ReservationExtraProps = { extras: IReservationExtras };

type IOwnProps = {
  config: ReservationConfig;
  allTables?: ITable[] | null;
  hasCalendar: boolean;
  onReservationCreate?: () => void;
  // these were url params before
  restaurant: NormalizedRestaurant;
  selectedDate?: string;
  selectedTableId?: string;
  type: 'full' | 'findPlace';
};

type TPropsWithoutFormik = IOwnProps & PropsFromRedux;

type TProps = FormikProps<AddReservationFormValues> & TPropsWithoutFormik;

class AddReservationFormikWrapper extends React.PureComponent<TProps, {}> {
  static propTypes = {
    config: Shapes.reservationConfigShape.isRequired,
    allTables: PropTypes.arrayOf(Shapes.restaurantTableShape.isRequired),
  };

  static defaultProps = {
    allTables: [],
  };

  componentDidMount(): void {
    this.props.setReservationFormValues(this.props.values);
  }

  componentDidUpdate(prevProps: TProps): void {
    if (
      prevProps.findPlaceSelectedTableIds !==
      this.props.findPlaceSelectedTableIds
    ) {
      const newSelectedTables: ITable[] = (this.props.allTables || []).filter(
        (table) => this.props.findPlaceSelectedTableIds.includes(table.id)
      );
      this.props.setSelectedTables(
        newSelectedTables.map((table) =>
          addPeopleAmountToTableTitle(table, {
            addPlace: true,
            allTables: this.props.allTables || [],
          })
        )
      );
    }

    if (prevProps.values !== this.props.values) {
      this.props.setReservationFormValues(this.props.values);
    }
  }

  componentWillUnmount(): void {
    this.props.resetFindPlace();
  }

  handleCustomerSelected = (customerData: {
    customer_name: string;
    phone_number: string;
  }) =>
    this.props.setValues({
      ...this.props.values,
      ...customerData,
    });

  updateVisitTime = (visitTime: string) =>
    this.props.setFieldValue('visitTime', visitTime);

  addAttachment = (savedFile: ISavedFile) => {
    this.props.setFieldValue('attachments', [
      ...(this.props.values.attachments || []),
      savedFile,
    ]);
  };

  deleteAttachment = (deletedIndex: number) => {
    const attachments = this.props.values.attachments || [];
    this.props.setFieldValue(
      'attachments',
      attachments.filter((item, index) =>
        index === deletedIndex
          ? {
              ...item,
              action: 'delete',
            }
          : item
      )
    );
  };

  handleTablesSelected = (tables: TTableWithPeopleAmount[]) =>
    this.props.setSelectedTables(tables);

  showReservationProposalAndClearSelectedTables = (
    tableSuggestions: TTableSuggestion[]
  ) => {
    const {
      showReservationProposalForMultipleTables,
      setSelectedTables,
      values,
    } = this.props;
    showReservationProposalForMultipleTables?.(tableSuggestions, {
      duration: values.duration as number,
      guestsNumber: values.guests_number,
      visitDate: new Date(values.visitDate),
      visitTime: values.visitTime,
    });
    setSelectedTables([]);
  };

  selectTables = (
    tables: TTableWithPeopleAmount[] | TTableSuggestionWithPeopleAmount[] | null
  ) => {
    if (!tables) {
      this.props.setFieldValue('tables', []);
      this.props.setSelectedTables([]);
      return;
    }
    this.props.setFieldValue('tables', tables);
    this.props.setSelectedTables(tables);
  };

  render() {
    const {
      config,
      findPlaceStep,
      handleChange,
      handleSubmit,
      restaurant,
      selectedTables,
      values,
      allTables,
    } = this.props;
    const { isAdmin } = this.props as PropsFromRedux;

    if (this.props.type === 'findPlace') {
      if (findPlaceStep === 'step1') {
        return (
          <>
            <AddReservationFormStep1
              config={config}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              values={values}
              contentAfterCoreInputs={this.renderContentAfterCoreInputs}
              updateVisitTime={this.updateVisitTime}
            />
            <EntirePlaceReservationHelper
              allTables={allTables}
              isEntirePlace={values.is_entire_place}
              onTablesSelected={this.selectTables}
              selectedTables={selectedTables}
            />
          </>
        );
      }
      if (findPlaceStep === 'step2') {
        return (
          <>
            <AddReservationFormStep1
              config={config}
              disabled
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              values={values}
              contentAfterCoreInputs={this.renderContentAfterCoreInputs}
              updateVisitTime={this.updateVisitTime}
            />
            <AddReservationFormStep2
              config={config}
              contentFooter={this.renderFooter('align-self-end mb-2')}
              goBack={this.props.findPlaceGoBack}
              handleCustomerSelected={this.handleCustomerSelected}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              values={values}
              restaurant={restaurant}
              selectedTables={selectedTables}
            />
            <EntirePlaceReservationHelper
              allTables={allTables}
              isEntirePlace={values.is_entire_place}
              onTablesSelected={this.selectTables}
              selectedTables={selectedTables}
            />
          </>
        );
      }
      return null;
    }

    return (
      <>
        <AddReservationFormFull
          isAdmin={isAdmin}
          config={config}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          restaurant={restaurant}
          values={values}
          contentAttachments={
            <ReservationAttachments
              attachments={values.attachments || null}
              canEdit
              onAdd={this.addAttachment}
              onDelete={this.deleteAttachment}
              renderFileUpload={this.renderFileUpload}
            />
          }
          contentRenderFlexibleTables={this.renderFlexibleTables}
          contentFooter={this.renderFooter()}
          handleCustomerSelected={this.handleCustomerSelected}
          updateVisitTime={this.updateVisitTime}
        />
        <EntirePlaceReservationHelper
          allTables={this.props.allTables}
          isEntirePlace={values.is_entire_place}
          onTablesSelected={this.selectTables}
          selectedTables={selectedTables}
        />
      </>
    );
  }

  renderFileUpload = ({
    onSuccess,
  }: {
    onSuccess: (file: ISavedFile) => void;
  }) => {
    return <FileUpload onSuccess={onSuccess} />;
  };

  renderContentAfterCoreInputs = (disabled?: boolean) => {
    const {
      calendarPreferences,
      findPlaceProceed,
      findPlaceStep,
      hasCalendar,
      selectedTables,
      updateCalendarPreferences,
    } = this.props;

    const proceed = () => {
      if (!selectedTables?.length) {
        toast.error('Najpierw wybierz stolik');
        return;
      }
      findPlaceProceed();
    };

    const toggleShowReservationsOnCalendar = () =>
      updateCalendarPreferences({
        showReservationSuggestionsOnCalendar:
          !calendarPreferences.showReservationSuggestionsOnCalendar,
      });

    return (
      <>
        {findPlaceStep === 'step2' ? (
          <SelectedTables tables={selectedTables as ITableWithPlace[]} />
        ) : (
          <>
            {this.renderFlexibleTables(disabled)}
            <div>
              <OtoButtons.AddButton
                color="primary"
                className="mr-2"
                onClick={proceed}
                outline={true}
              >
                Dalej: Podaj dane Gości
              </OtoButtons.AddButton>
            </div>
          </>
        )}
        {hasCalendar &&
        calendarPreferences.showReservationSuggestionsOnCalendar ? (
          <OtoButtons.DeleteButton
            className="mr-2"
            onClick={toggleShowReservationsOnCalendar}
          >
            Ukryj propozycje stolików
          </OtoButtons.DeleteButton>
        ) : (
          <OtoButtons.AddButton
            color="primary"
            className="mr-2 mb-2"
            onClick={toggleShowReservationsOnCalendar}
            outline={true}
          >
            Pokaż propozycje stolików
          </OtoButtons.AddButton>
        )}
      </>
    );
  };

  renderFlexibleTables = (disabled?: boolean) => {
    const {
      allTables,
      restaurant,
      selectedTables,
      suggestedTables,
      values,
      tableSuggestionsLoading,
    } = this.props;

    if (!allTables) {
      return null;
    }

    return (
      <AddReservationTablesSelect
        allTables={allTables}
        classNameTablesField="col p-0 mr-3 min-w-300 mw-400"
        classNameTablesAvailabilityField="col p-0 min-w-200 mw-250"
        disabled={disabled}
        loadTableSuggestions={this.props.loadTableSuggestions}
        onTablesSelected={this.handleTablesSelected}
        onSuggestionsLoaded={this.showReservationProposalAndClearSelectedTables}
        selectedTables={selectedTables}
        restaurant={restaurant}
        suggestedTables={suggestedTables}
        tableSuggestionsLoading={tableSuggestionsLoading}
        values={values}
      />
    );
  };

  renderError = (key: string) => {
    const { errors, touched } = this.props;
    const fieldsThatMayBeUntouched = ['tables'];
    const mayBeUntouched = fieldsThatMayBeUntouched.includes(key);
    return errors[key] && (touched[key] || mayBeUntouched) ? (
      <div className="text-danger">{errors[key]}</div>
    ) : null;
  };

  renderFooter = (className: string = '') => {
    const { isSubmitting } = this.props;
    if (isSubmitting) {
      return <OtoSpinner center />;
    }

    return (
      <div className={className}>
        {this.renderError('customer_name')}
        {this.renderError('phone_number')}
        {this.renderError('guests_number')}
        {this.renderError('tables')}
        {this.renderError('kids_number')}
        {this.renderError('assignee')}
        {this.renderError('duration')}
        <Button className="mt-2" type="submit">
          {i18next.t('Save')}
        </Button>
      </div>
    );
  };
}

const mapPropsToValues = (
  props: TPropsWithoutFormik
): AddReservationFormValues => {
  const { config, selectedDate, selectedTableId } = props;

  const passedTableId = selectedTableId && parseInt(selectedTableId, 10);
  const tables = props.allTables || [];
  const currentRestaurantTable = tables.find(
    (table) => table.id === passedTableId
  );

  const values: AddReservationFormValues = {
    customer_name: '',
    phone_number: '',
    restaurant_id: props.restaurant.id,
    visitDate: toDateInputValue(
      selectedDate ? new Date(selectedDate) : addDays(new Date(), 1)
    ),
    visitTime:
      selectedDate && selectedDate.length > 11
        ? selectedDate.slice(11, 16)
        : '16:00',
    guests_number: currentRestaurantTable?.min_people || 2,
    duration: getDefaultDuration(config),
    notes_from_restaurant: '',
    is_entire_place: false,
    source: ReservationSource.dashboard,
  };
  if (hasAssignee(config)) {
    values.assignee = '';
  }
  if (hasKids(config)) {
    values.kids_number = 0;
  }
  return values;
};
const validate = (
  values: AddReservationFormValues,
  props: TPropsWithoutFormik
): FormikErrors<AddReservationFormValues> => {
  const { config, selectedTables } = props;
  const errors: Record<string, string> = {};
  if (!values.customer_name) {
    errors.customer_name = 'Imię lub nazwisko jest wymagane';
  }
  if (!values.phone_number) {
    errors.phone_number = 'Pole numer kontaktowy jest wymagane';
  } else if (values.phone_number.length < 9) {
    errors.phone_number =
      'Numer kontaktowy powinien zawierać przynajmniej 9 cyfr';
  }
  if (!values.guests_number) {
    errors.guests_number = 'Pole liczba osób jest wymagane';
  } else if (values.guests_number < 0) {
    errors.guests_number = 'Pole liczba osób powinno być większe od 0';
  }
  if (hasFlexibleTables(config)) {
    if (!selectedTables?.length) {
      errors.tables = 'Pole stolik jest wymagane';
    }
  }
  if (hasAssignee(config)) {
    if (!values.assignee) {
      errors.assignee = 'Pole osoba przyjmująca jest wymagane';
    }
  }
  if (hasKids(config)) {
    if (!values.kids_number && values.kids_number !== 0) {
      errors.kids_number = 'Pole liczba dzieci jest wymagane';
    }
  }
  if (!values.duration || values.duration < 60) {
    errors.duration = 'Pole długość rezerwacji jest uzupełnione niepoprawnie';
  }
  return errors;
};
const handleSubmit = (
  values: AddReservationFormValues,
  {
    setSubmitting,
    props,
  }: FormikBag<TPropsWithoutFormik, AddReservationFormValues>
): void | Promise<void> => {
  const { config, selectedTables } = props;
  const extraProps: ReservationExtraProps | {} = hasAssignee(config)
    ? {
        extras: {
          assignee: values.assignee,
        },
      }
    : {};
  if (hasKids(config)) {
    (extraProps as ReservationExtraProps).extras = {
      ...((extraProps as ReservationExtraProps).extras || {}),
      kids_number: values.kids_number,
    };
  }
  const payload: CreateReservationDTO = {
    ...values,
    reserved_on: generateReservedOn(values),
    ...(selectedTables
      ? { tables: selectedTables.map((table: ITable) => table.id) }
      : {}),
    ...extraProps,
  };
  return props
    .addReservation(payload)
    .then(() => props.onReservationCreate && props.onReservationCreate())
    .finally(() => setSubmitting(false));
};

const AddReservationFormWithFormik = withFormik<
  TPropsWithoutFormik,
  AddReservationFormValues
>({
  mapPropsToValues,
  validate,
  handleSubmit,
  displayName: 'AddReservationForm',
})(AddReservationFormikWrapper);

const mapStateToProps = (state: IAppState) => ({
  calendarPreferences: selectCalendarPreferences(state),
  findPlaceStep: state.reservationsCalendar.findPlaceStep,
  findPlaceSelectedTableIds:
    state.reservationsCalendar.findPlaceSelectedTableIds,
  isAdmin: selectIsRootAdmin(state),
  selectedTables: selectSelectedTables(state),
  suggestedTables: selectTableSuggestions(state),
  tableSuggestionsLoading: selectTableSuggestionsLoading(state),
});

const mapDispatchToProps = {
  addReservation,
  findPlaceGoBack,
  findPlaceProceed,
  loadTableSuggestions,
  resetFindPlace,
  setReservationFormValues,
  showReservationProposalForMultipleTables,
  setSelectedTables,
  updateCalendarPreferences,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const EnhancedAddReservationForm = connector(AddReservationFormWithFormik);

export default EnhancedAddReservationForm;
