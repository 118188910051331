import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { toast } from 'react-toastify';

import { checkToken, checkAppVersion } from 'services/auth';
import { LOG_BAG } from 'utils/log';
import {
  addPeopleAmountToTableTitle,
  DECLINE_REASON_BY_CLIENT,
  DECLINE_REASON_NO_SHOW,
  isReservedAutomatically,
  isReservationsConfigFinished,
} from 'utils/reservation';
import { loadCssFile } from 'utils/general';
import { FREQUENCY } from 'config';
import ReservationsModal from 'app/components/reservations/ReservationsModal';
import ReservationsCalendar from 'app/components/reservations/ReservationsCalendar';
import {
  declineReservation,
  selectReservations,
  selectReservationsLoading,
  setReservations,
  setReservationsLoading,
  updateReservation,
} from 'store/reservations';
import PendingOrdersContainer from '../containers/PendingOrdersContainer';
import OrdersFetcher from '../containers/OrdersFetcher';
import {
  selectPlaces,
  selectPlacesLoading,
  loadRestaurantPlaces,
  selectRestaurant,
  updateOrdersConfig,
  selectBookingConfig,
  selectRestaurantTables,
  selectOrdersConfig,
} from 'store/restaurant';
import Restaurant, { hasReservationsPrint } from 'utils/restaurant';
import PreviewLink, { RestaurantPreviewType } from 'app/views/ViewPreviewLink';
import ReservationsDaysLimitFilter, {
  daysLimitFilterOptions,
} from 'app/components/reservations/ReservationsDaysLimitFilter';

import ReservationConfigNotFinishedAlert from 'app/components/alerts/ReservationConfigNotFinishedAlert';
import ReservationsFetcher from '../containers/ReservationsFetcher';
import type {
  ISavedFile,
  ISavedReservation,
  ITableWithPlace,
  ITableWithPlaceName,
  OrdersConfig,
  NormalizedRestaurant,
} from 'types';
import { IAppState } from 'store/main';
import CollapsibleCard from 'app/components/common/CollapsibleCard';
import AddReservationForm from './AddReservationFormikWrapper';
import {
  loadTableSuggestions,
  resetFindPlace,
  selectCalendarPreferences,
  selectProposedTables,
  selectReservationProposal,
  selectReservationFormValues,
  selectTableSuggestions,
  selectTableSuggestionsLoading,
  toggleTableSelection,
  updateCalendarPreferences,
} from 'store/reservation-calendar';
import RestaurantIsNotLiveAlert from 'app/components/alerts/RestaurantIsNotLiveAlert';
import OtoSpinner from 'app/components/common/OtoSpinner';
import ReservationsLegend from 'app/components/reservations/ReservationsLegend';
import ReservationReleaseNotes from 'app/components/reservations/ReservationReleaseNotes';
import ViewReservationsPlace from 'app/views/ViewReservationsPlace';
import ErrorBoundary from '../containers/ErrorBoundary';
import FileUpload from 'app/components/common/FileUpload';
import ReservationsViewOptions from 'app/components/reservations/ReservationsViewOptions';
import { withRouter, WithRouterProps } from 'app/containers/withRouter';
import OtoAlert from 'app/components/common/OtoAlert';
import GuestCard from 'app/components/reservations/GuestCard';
import {
  createCustomerNoteByPhone,
  getCustomerNotesForReservation,
  loadCustomerNotes,
  selectAddCustomerNoteLoading,
  selectCustomerNotes,
  selectCustomerNotesLoading,
} from 'store/customers';
import ReservationMarketingAgreements from 'app/components/reservations/ReservationMarketingAgreements';

interface ReservationsCalendarPageState {
  daysLimitFilter: string;
  modalReservation: ISavedReservation | null;
  flexibleTables: boolean;
  tablesConfig: ITableWithPlaceName[];
}

type ReservationsListPageProps = PropsFromRedux & WithRouterProps;

const COMPONENT_NAME = 'ReservationsCalendarPage';
class ReservationsCalendarPage extends React.PureComponent<
  ReservationsListPageProps,
  ReservationsCalendarPageState
> {
  reloadInterval: number | null = null;
  _isMounted: boolean = false;

  constructor(props: ReservationsListPageProps) {
    super(props);
    this.checkUpToDateVersion();
    if (checkToken()) {
      const config = props.config || {};
      const flexibleTables = config.flexible_tables;
      this.state = {
        daysLimitFilter: daysLimitFilterOptions[0].value,
        modalReservation: null,
        flexibleTables,
        tablesConfig: [],
      };
      if (flexibleTables) {
        const tablesConfig = props.tablesConfig.map((tableConfig) => ({
          ...tableConfig,
          placeName: tableConfig.place!.name,
          nameWithPeopleAmount: `${tableConfig.name} (${tableConfig.min_people}-${tableConfig.max_people} os.)`,
        }));
        this.state = {
          ...this.state,
          tablesConfig,
        };
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const { loadCustomerNotes, loadRestaurantPlaces, restaurant, places } =
      this.props;
    if (!restaurant) {
      return;
    }
    if (hasReservationsPrint(restaurant)) {
      loadCssFile('reservations-rollpaper-print.css');
    }
    if (this.state.flexibleTables && !places?.length) {
      loadRestaurantPlaces(restaurant);
    }
    loadCustomerNotes(restaurant.id);
  }

  componentDidCatch(error, info) {
    LOG_BAG.logComponentDidCatch(COMPONENT_NAME, error, info);
    this.showGeneralToastError();
  }

  componentWillUnmount() {
    this.reloadInterval && clearInterval(this.reloadInterval);
    this._isMounted = false;
    this.props.resetFindPlace();
  }

  checkUpToDateVersion = () => {
    this.reloadInterval = setInterval(checkAppVersion, FREQUENCY.CHECK_VERSION);
    checkAppVersion();
  };

  showGeneralToastError = () => {
    toast.error('Wystąpił problem. Odśwież stronę.', {
      autoClose: false,
    });
  };

  handleReservationDelete = (reservation: ISavedReservation) => {
    this.props
      .declineReservation({
        reservation,
        successMessage: 'Rezerwacja została pomyślnie usunięta',
        errorMessage: 'Błąd kasowania rezerwacji!',
        declineCode: DECLINE_REASON_BY_CLIENT,
      })
      .then(() => this.closeModal());
  };

  handleReportNoShow = (reservation: ISavedReservation) => {
    this.props
      .declineReservation({
        reservation,
        successMessage: 'Rezerwacja została pomyślnie zaktualizowana',
        errorMessage: 'Błąd aktualizacji rezerwacji!',
        declineCode: DECLINE_REASON_NO_SHOW,
      })
      .then(() => this.closeModal());
  };

  openModal = (reservation: ISavedReservation) =>
    this.setState({
      modalReservation: {
        ...reservation,
        ...(reservation.tables
          ? {
              tables: reservation.tables.map(
                (table) =>
                  addPeopleAmountToTableTitle(table, {
                    addPlace: true,
                    allTables: this.state.tablesConfig,
                  }) as ITableWithPlace // TODO fix types here
              ),
            }
          : {}),
      },
    });

  closeModal = () => this.setState({ modalReservation: null });

  navigateToAddReservation = ({
    date,
    table,
  }: {
    date: string;
    table?: { id: string };
  }) => {
    const tableUriPart = table && table.id ? `/${table.id}` : '';
    this.props.navigate(`/add-reservation/${date}${tableUriPart}`);
  };

  handleChangeDaysLimitFilter = (e) => {
    this.reloadInterval && clearInterval(this.reloadInterval);
    this.setState({ daysLimitFilter: e.target.value });
  };

  handleTableSelectOnMap = (table: ITableWithPlace) => {
    this.props.toggleTableSelection(
      addPeopleAmountToTableTitle(table, {
        addPlace: true,
        allTables: this.state.tablesConfig,
      })
    );
  };

  handleCustomerNoteAdd = async ({
    note,
    modalReservation,
    restaurant,
  }: {
    note: string;
    modalReservation: ISavedReservation;
    restaurant: NormalizedRestaurant;
  }): Promise<boolean> => {
    try {
      const createdCustomerNote = await this.props.createCustomerNoteByPhone({
        customerPhone: modalReservation.phone_number,
        note,
        restaurantId: restaurant.id,
      });
      return !!createdCustomerNote;
    } catch (e) {
      return false;
    }
  };

  render() {
    const {
      calendarPreferences,
      customerNotes,
      customerNotesLoading,
      customerNotesNewNoteLoading,
      config,
      ordersConfig,
      loadTableSuggestions,
      places,
      reservations,
      reservationProposal,
      reservationsLoading,
      tableSuggestionsLoading,
      tableSuggestions,
      updateCalendarPreferences,
    } = this.props;
    const restaurant = this.props.restaurant as NormalizedRestaurant;
    const placesLoading = !places?.length && this.state.flexibleTables;

    if (!isReservationsConfigFinished(config)) {
      return <ReservationConfigNotFinishedAlert />;
    }

    const { flexibleTables, modalReservation, tablesConfig, daysLimitFilter } =
      this.state;
    const reservationsWithoutCode = (reservations || []).filter(
      (res) => !res.code
    );
    if (reservationsWithoutCode.length) {
      LOG_BAG.logError(
        `ReservationsList found: ${reservationsWithoutCode.length} items without code`,
        reservationsWithoutCode
      );
    }

    return (
      <>
        <OrdersFetcher
          ordersConfig={ordersConfig}
          frequency={FREQUENCY.REFRESH_SECONDARY}
        />
        <ReservationsFetcher
          config={config}
          daysLimitFilter={this.state.daysLimitFilter}
          frequency={FREQUENCY.REFRESH}
          includeTablesAndTheirPlaces={this.state.flexibleTables}
          restaurant={restaurant}
        />
        <PendingOrdersContainer />
        <PreviewLink
          color="link"
          className="px-0"
          restaurant={restaurant}
          type={RestaurantPreviewType.Resevations}
        />
        {!restaurant.restaurant_opening_hours.length && (
          <OtoAlert color="danger">
            Brak informacji na temat godzin otwarcia restauracji.
            <br />
            Podaj otwarcia w zakładce "Ustawienia" -&gt; "Godziny otwarcia".
          </OtoAlert>
        )}
        <ReservationsLegend />
        <ReservationReleaseNotes restaurant={restaurant} />
        <CollapsibleCard
          className={'no-print find-place-card'}
          title={
            'Znajdź miejsce: pokaż mapę, wybierz stoliki i dodaj rezerwację'
          }
        >
          {(open) =>
            open ? (
              <>
                <AddReservationForm
                  allTables={tablesConfig}
                  config={config}
                  hasCalendar={true}
                  restaurant={restaurant}
                  // @TODO use proper navigation
                  onReservationCreate={() => this.props.navigate('/calendar')}
                  type="findPlace"
                />
                <hr className="my-2" />
                <ErrorBoundary componentName="ReservationsPlace">
                  <ViewReservationsPlace
                    onReservationClick={this.openModal}
                    onTableSelect={this.handleTableSelectOnMap}
                  />
                </ErrorBoundary>
              </>
            ) : null
          }
        </CollapsibleCard>
        <RestaurantIsNotLiveAlert type="reservations" restaurant={restaurant} />
        {(reservationsLoading || placesLoading) && (
          <OtoSpinner className="mb-2" />
        )}
        <ReservationsViewOptions
          places={places || []}
          preferences={calendarPreferences}
          showPlaceSelect={flexibleTables}
          showHours={flexibleTables}
          updatePreferences={updateCalendarPreferences}
        >
          <ReservationsDaysLimitFilter
            onChange={this.handleChangeDaysLimitFilter}
            value={daysLimitFilter}
          />
        </ReservationsViewOptions>
        <ReservationsCalendar
          config={config}
          onOpenModalClick={this.openModal}
          onCalendarDateClick={this.navigateToAddReservation}
          onProposedTablesSelect={this.props.selectProposedTables}
          places={places}
          preferences={this.props.calendarPreferences}
          restaurant={restaurant}
          reservationFormValues={this.props.reservationFormValues}
          reservations={reservations}
          reservationProposal={reservationProposal}
          tables={tablesConfig}
          tableSuggestions={this.props.tableSuggestions}
          updateReservation={this.props.updateReservation}
          updateCalendarPreferences={this.props.updateCalendarPreferences}
        />
        {!!modalReservation && (
          <ErrorBoundary componentName={'ReservationsModal'}>
            <ReservationsModal
              reservation={modalReservation}
              restaurant={restaurant}
              config={config}
              tables={flexibleTables ? tablesConfig : null}
              onSave={this.props.updateReservation}
              onClose={this.closeModal}
              onCancel={this.handleReservationDelete}
              onLogError={LOG_BAG.logError}
              loadTableSuggestions={loadTableSuggestions}
              renderFileUpload={this.renderFileUpload}
              reportNoShow={this.handleReportNoShow}
              suggestedTables={tableSuggestions}
              tableSuggestionsLoading={tableSuggestionsLoading}
            >
              <GuestCard
                customerNotes={getCustomerNotesForReservation(
                  customerNotes,
                  modalReservation
                )}
                className={'mt-3'}
                isLoadingCustomerNotes={customerNotesLoading}
                isNoteBeingCreated={customerNotesNewNoteLoading}
                onNoteAdd={(note: string) =>
                  this.handleCustomerNoteAdd({
                    note,
                    modalReservation,
                    restaurant,
                  })
                }
              />
              {Restaurant.wantsToCollectMarketingAgreements(restaurant) && (
                <ReservationMarketingAgreements
                  className={'mt-2'}
                  isAddedByRestaurant={!isReservedAutomatically(modalReservation)}
                  reservation={modalReservation}
                />
              )}
            </ReservationsModal>
          </ErrorBoundary>
        )}
      </>
    );
  }

  renderFileUpload = ({
    onSuccess,
  }: {
    onSuccess: (file: ISavedFile) => void;
  }) => {
    return <FileUpload onSuccess={onSuccess} />;
  };
}
const mapStateToProps = (state: IAppState) => ({
  calendarPreferences: selectCalendarPreferences(state),
  config: selectBookingConfig(state),
  customerNotes: selectCustomerNotes(state),
  customerNotesLoading: selectCustomerNotesLoading(state),
  customerNotesNewNoteLoading: selectAddCustomerNoteLoading(state),

  ordersConfig: selectOrdersConfig(state) as OrdersConfig,

  reservationFormValues: selectReservationFormValues(state),
  reservations: selectReservations(state),
  reservationsLoading: selectReservationsLoading(state),

  restaurant: selectRestaurant(state),

  places: selectPlaces(state),
  placesLoading: selectPlacesLoading(state),

  reservationProposal: selectReservationProposal(state),
  tableSuggestions: selectTableSuggestions(state),
  tableSuggestionsLoading: selectTableSuggestionsLoading(state),

  tablesConfig: selectRestaurantTables(state),
});

const mapDispatchToProps = {
  createCustomerNoteByPhone,
  declineReservation,
  loadCustomerNotes,
  loadTableSuggestions,
  loadRestaurantPlaces,
  resetFindPlace,
  selectProposedTables,
  setReservations,
  setReservationsLoading,
  toggleTableSelection,
  updateReservation,
  updateOrdersConfig,
  updateCalendarPreferences,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(ReservationsCalendarPage));
