import React, { useState } from 'react';
import { Phone } from 'react-feather';
import { toast } from 'react-toastify';

import type { AnyRestaruant } from 'types';
import { OtoButtons } from 'app/components/common';
import useSetModal from 'hooks/useSetModal';
import { ModalTypes } from 'enums';
import APIService from 'services/api';

export type ViewMakeTestPhoneCallToRestaurantProps = {
  restaurant: AnyRestaruant;
};
const ViewMakeTestPhoneCallToRestaurant: React.FC<ViewMakeTestPhoneCallToRestaurantProps> =
  ({ restaurant }) => {
    const setModal = useSetModal();
    const [isLoading, setIsLoading] = useState(false);

    const makeTestPhoneCall = async () => {
      setIsLoading(true);
      try {
        await APIService.post(
          `/restaurants/${restaurant.id}/notifications/test/phone`,
          {}
        );
        toast.success('Testowe połączenie telefoniczne zostało wykonane');
      } catch (error) {
        toast.error(
          'Nie udało się wykonać testowego połączenia telefonicznego'
        );
      } finally {
        setIsLoading(false);
      }
    };

    const handleClick = () => {
      setModal(
        {
          title: `Testowe połączenie telefoniczne z restauracją ${restaurant.name}`,
          text: `Czy chcesz wykonać testowe połączenie telefoniczne z restauracją ${restaurant.name}?`,
          confirmColor: 'info',
          confirm: makeTestPhoneCall,
        },
        ModalTypes.CONFIRM
      );
    };

    return (
      <>
        <OtoButtons.GenericButton
          loading={isLoading}
          icon={<Phone />}
          onClick={handleClick}
        >
          Zrób testowe połączenie telefoniczne
        </OtoButtons.GenericButton>
      </>
    );
  };

export default ViewMakeTestPhoneCallToRestaurant;
