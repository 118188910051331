import { toast } from 'react-toastify';
import memoizeOne from 'memoize-one';

import APIService from 'services/api';
import Logger from 'utils/log';
import { normalizePhoneNumber } from 'utils/normalize-phone-number';
import { LOGOUT } from './app';
import { EAsyncStatus } from 'enums';
import type {
  ISavedReservation,
  TCustomerNote,
  TCustomerWithOrderStats,
} from 'types';

export interface CustomersReducerState {
  customers: TCustomerWithOrderStats[];
  customersStatus: EAsyncStatus;
  customersError: any;

  customerNotes: TCustomerNote[];
  customerNotesStatus: EAsyncStatus;
  customerNotesError: any;

  addCustomerNoteStatus: EAsyncStatus;
}

export const INITIAL_STATE: CustomersReducerState = {
  customers: [],
  customersStatus: EAsyncStatus.IDLE,
  customersError: null,

  customerNotes: [],
  customerNotesStatus: EAsyncStatus.IDLE,
  customerNotesError: null,

  addCustomerNoteStatus: EAsyncStatus.IDLE,
};

export const SET_CUSTOMERS_LOADING = 'SET_CUSTOMERS_LOADING';
export const SET_CUSTOMERS_LOAD_SUCCESS = 'SET_CUSTOMERS_LOAD_SUCCESS';
export const SET_CUSTOMERS_LOAD_ERROR = 'SET_CUSTOMERS_LOAD_ERROR';

const LOAD_CUSTOMER_NOTES_REQURESTED = 'LOAD_CUSTOMER_NOTES_REQURESTED';
const LOAD_CUSTOMER_NOTES_RESOLVED = 'LOAD_CUSTOMER_NOTES_RESOLVED';
const LOAD_CUSTOMER_NOTES_REJECTED = 'LOAD_CUSTOMER_NOTES_REJECTED';

const ADD_CUSTOMER_NOTE_REQUESTED = 'ADD_CUSTOMER_NOTE_REQUESTED';
const ADD_CUSTOMER_NOTE_RESOLVED = 'ADD_CUSTOMER_NOTE_RESOLVED';
const ADD_CUSTOMER_NOTE_REJECTED = 'ADD_CUSTOMER_NOTE_REJECTED';

type CustomersStoreAction =
  | {
      type: typeof SET_CUSTOMERS_LOADING;
    }
  | {
      type: typeof SET_CUSTOMERS_LOAD_SUCCESS;
      customers: TCustomerWithOrderStats[];
    }
  | {
      type: typeof SET_CUSTOMERS_LOAD_ERROR;
      error: any;
    }
  | {
      type: typeof LOAD_CUSTOMER_NOTES_REQURESTED;
    }
  | {
      type: typeof LOAD_CUSTOMER_NOTES_RESOLVED;
      customerNotes: TCustomerNote[];
    }
  | {
      type: typeof LOAD_CUSTOMER_NOTES_REJECTED;
      error: any;
    }
  | {
      type: typeof ADD_CUSTOMER_NOTE_REQUESTED;
      note: string;
    }
  | {
      type: typeof ADD_CUSTOMER_NOTE_RESOLVED;
      note: TCustomerNote;
    }
  | {
      type: typeof ADD_CUSTOMER_NOTE_REJECTED;
      error: any;
    }
  | {
      type: typeof LOGOUT;
    };

function customersReducer(
  state = INITIAL_STATE,
  action: CustomersStoreAction
): CustomersReducerState {
  switch (action.type) {
    case SET_CUSTOMERS_LOADING:
      return {
        ...state,
        customersStatus: EAsyncStatus.PENDING,
        customersError: null,
      };
    case SET_CUSTOMERS_LOAD_SUCCESS:
      return {
        ...state,
        customers: action.customers,
        customersStatus: EAsyncStatus.RESOLVED,
        customersError: null,
      };
    case SET_CUSTOMERS_LOAD_ERROR:
      return {
        ...state,
        customers: [],
        customersStatus: EAsyncStatus.REJECTED,
        customersError: action.error,
      };
    case LOAD_CUSTOMER_NOTES_REQURESTED:
      return {
        ...state,
        customerNotesStatus: EAsyncStatus.PENDING,
        customerNotesError: null,
      };
    case LOAD_CUSTOMER_NOTES_RESOLVED:
      return {
        ...state,
        customerNotes: action.customerNotes,
        customerNotesStatus: EAsyncStatus.RESOLVED,
        customerNotesError: null,
      };
    case LOAD_CUSTOMER_NOTES_REJECTED:
      return {
        ...state,
        customerNotes: [],
        customerNotesStatus: EAsyncStatus.REJECTED,
        customerNotesError: action.error,
      };
    case ADD_CUSTOMER_NOTE_REQUESTED:
      return {
        ...state,
        addCustomerNoteStatus: EAsyncStatus.PENDING,
      };
    case ADD_CUSTOMER_NOTE_RESOLVED:
      return {
        ...state,
        addCustomerNoteStatus: EAsyncStatus.RESOLVED,
        customerNotes: [action.note, ...state.customerNotes],
      };
    case ADD_CUSTOMER_NOTE_REJECTED:
      return {
        ...state,
        addCustomerNoteStatus: EAsyncStatus.REJECTED,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export const setCustomersLoadSuccess = (
  customers: TCustomerWithOrderStats[]
): CustomersStoreAction => ({
  type: SET_CUSTOMERS_LOAD_SUCCESS,
  customers,
});
export const setCustomersLoadError = (e): CustomersStoreAction => ({
  type: SET_CUSTOMERS_LOAD_ERROR,
  error: e,
});

export const loadCustomers = (restaurantId: number) => (dispatch) => {
  const url = `/restaurants/${restaurantId}/customers`;
  dispatch({ type: SET_CUSTOMERS_LOADING } as CustomersStoreAction);
  return APIService.get(url)
    .then((customers) => dispatch(setCustomersLoadSuccess(customers)))
    .catch((e) => {
      Logger.fetchError({ e, url });
      toast.error('Problem pobierania listy użytkowników');
      return dispatch(setCustomersLoadError(e));
    });
};

export const loadCustomerNotes = (restaurantId: number) => (dispatch) => {
  const url = `/restaurants/${restaurantId}/customer-notes`;

  dispatch({ type: LOAD_CUSTOMER_NOTES_REQURESTED } as CustomersStoreAction);
  return APIService.get(url)
    .then((customerNotes) => {
      dispatch({
        type: LOAD_CUSTOMER_NOTES_RESOLVED,
        customerNotes,
      } as CustomersStoreAction);
    })
    .catch((e) => {
      Logger.fetchError({
        e,
        url,
      });
      toast.error('Problem pobierania Karty Gościa');
      dispatch({
        type: LOAD_CUSTOMER_NOTES_REJECTED,
        error: e,
      } as CustomersStoreAction);
    });
};

export const createCustomerNoteByPhone =
  ({
    customerPhone,
    restaurantId,
    note,
  }: {
    customerPhone: string;
    restaurantId: number;
    note: string;
  }) =>
  (dispatch) => {
    const url = `/restaurants/${restaurantId}/customer-notes`;

    dispatch({
      type: ADD_CUSTOMER_NOTE_REQUESTED,
      note,
    } as CustomersStoreAction);
    const payload: Partial<TCustomerNote> = {
      customer_phone: customerPhone,
      note,
      restaurant_id: restaurantId,
    };
    return APIService.post(url, payload)
      .then((note: TCustomerNote) => {
        dispatch({
          type: ADD_CUSTOMER_NOTE_RESOLVED,
          note,
        } as CustomersStoreAction);
        toast.success('Notatka została dodana');
        return note;
      })
      .catch((e) => {
        Logger.fetchError({
          e,
          url,
        });
        toast.error('Problem dodawania notatki');
        dispatch({
          type: ADD_CUSTOMER_NOTE_REJECTED,
          error: e,
        } as CustomersStoreAction);
        return null;
      });
  };

export default customersReducer;

type TAppStateWithCustomersReducer = {
  customers: CustomersReducerState;
};

// selectors
export const selectCustomers = (state: TAppStateWithCustomersReducer) =>
  state.customers.customers;
export const selectCustomersLoading = (state: TAppStateWithCustomersReducer) =>
  state.customers.customersStatus === EAsyncStatus.PENDING;
export const selectCustomersError = (state: TAppStateWithCustomersReducer) =>
  state.customers.customersError;

export const selectCustomerNotes = (state: TAppStateWithCustomersReducer) =>
  state.customers.customerNotes;
export const selectCustomerNotesLoading = (
  state: TAppStateWithCustomersReducer
) => state.customers.customerNotesStatus === EAsyncStatus.PENDING;
export const selectAddCustomerNoteLoading = (
  state: TAppStateWithCustomersReducer
) => state.customers.addCustomerNoteStatus === EAsyncStatus.PENDING;

// custom helpers - not selectors, because receive store as params
export const getCustomerNotesForReservation = memoizeOne(
  (
    customerNotes: TCustomerNote[],
    reservation: ISavedReservation
  ): TCustomerNote[] =>
    getCustomerNotesByPhone(customerNotes, reservation.phone_number)
);

export const getCustomerNotesByPhone = (
  customerNotes: TCustomerNote[],
  phone: string
): TCustomerNote[] => {
  const normalizedPhone = normalizePhoneNumber(phone);
  return customerNotes.filter(
    (note) =>
      note.customer_phone &&
      normalizePhoneNumber(note.customer_phone) === normalizedPhone
  );
};
