import React from 'react';

import SpecialDaysSettings from 'app/pages/SpecialDaysSettings';
import ContactPage from 'app/pages/ContactPage';
import MenuEditPage from 'app/pages/MenuEditPage';
import MarketingPage from 'app/pages/MarketingPage';
import PromoCodesPage from 'app/pages/PromoCodesPage';
import DiscountsPage from 'app/pages/DiscountsPage';
import WelcomePage from 'app/pages/Welcome';
import IntegrationsPage from 'app/pages/RestaurantIntegrationsPage';
import OrderFeedbackPage from 'app/pages/OrderFeedbackPage';
import RestaurantSettingsPage from 'app/pages/RestaurantSettingsPage';
import ReferralsPage from 'app/pages/ReferralsPage';
import RestaurantPayoutsPage from 'app/pages/RestaurantPayoutsPage';
import StatisticsPage from 'app/pages/StatisticsPage';
import { OTO_PRODUCTS } from 'enums';
import { ROLES } from 'enums';
import { IRoute } from './types';
import {
  adminDeliveriesRoute,
  adminStatisticsRoute,
  adminRestaurantListRoute,
  adminUsersRoute,
  adminRestaurantCompaniesRoute,
  adminOrderDeclinationRoute,
  adminPaymentsRoute,
  adminPayoutsRoute,
  adminFleetManagerRoute,
  adminIntegrationsRoute,
  rootAdminDashboardRoute,
  tenantAdminDashboardRoute,
  adminCustomersRoute,
} from './admin';
import {
  addReservationRoute,
  addReservationWithDateAndTableRoute,
  addReservationWithDateRoute,
  findReservationRoute,
  placeMapViewRoute,
  reservationCustomersRoute,
  reservationListRoute,
  reservationSettingsRoute,
} from './reservations';
import {
  ordersListRoute,
  ordersListWithModalRoute,
  ordersSettingsRoute,
} from './orders';
import {
  deliveryCoordinatorDeliveriesListRoute,
  driverDeliveriesListRoute,
  restaurantDeliveriesListRoute,
  restaurantDriversRoute,
} from './deliveries';

export type { IRoute };

const allRolesButRestaurantStaff = Object.values(ROLES).filter(
  (roleId) => roleId !== ROLES.RESTAURANT_STAFF
) as ROLES[];

const referralsPageRoute: IRoute = {
  component: ReferralsPage,
  name: ReferralsPage.navName,
  path: ReferralsPage.url,
};

const helpPageRoute: IRoute = {
  name: 'Pomoc',
  path: '/help',
  component: ContactPage as React.ComponentType<any>,
};

const pageList: IRoute[] = [
  ordersListRoute,
  ordersListWithModalRoute,
  restaurantDeliveriesListRoute,
  restaurantDriversRoute,
  reservationListRoute,
  addReservationRoute,
  addReservationWithDateRoute,
  addReservationWithDateAndTableRoute,
  placeMapViewRoute,
  findReservationRoute,
  reservationCustomersRoute,
  {
    name: RestaurantSettingsPage.navName,
    path: RestaurantSettingsPage.url,
    component: RestaurantSettingsPage as React.ComponentType<any>,
  },
  reservationSettingsRoute,
  ordersSettingsRoute,
  {
    name: 'Edytuj menu',
    path: '/menu',
    component: MenuEditPage,
  },
  {
    name: (DiscountsPage as any).navName,
    path: (DiscountsPage as any).url,
    component: DiscountsPage as React.ComponentType<any>,
  },
  {
    name: 'Dni wyjątkowe',
    path: '/settings/special-days',
    component: SpecialDaysSettings,
  },
  {
    name: IntegrationsPage.navName as string,
    path: IntegrationsPage.url as string,
    component: IntegrationsPage,
  },
  {
    name: 'Kody promocyjne',
    path: '/settings/promo-codes',
    // @ts-ignore todo fix props
    component: PromoCodesPage,
  },
  {
    name: 'Statystyki',
    path: '/statistics/*',
    component: StatisticsPage,
    availableForRoles: allRolesButRestaurantStaff,
    index: true,
  },
  {
    name: RestaurantPayoutsPage.navName,
    path: RestaurantPayoutsPage.url,
    // @ts-ignore todo fix props
    component: RestaurantPayoutsPage,
    availableForRoles: [ROLES.ADMIN, ROLES.RESTAURANT_ADMIN],
    availableWithProducts: [OTO_PRODUCTS.orders],
  },
  rootAdminDashboardRoute,
  adminStatisticsRoute,
  tenantAdminDashboardRoute,
  adminRestaurantListRoute,
  adminUsersRoute,
  adminRestaurantCompaniesRoute,
  adminOrderDeclinationRoute,
  adminDeliveriesRoute,
  adminPaymentsRoute,
  adminPayoutsRoute,
  adminFleetManagerRoute,
  adminIntegrationsRoute,
  adminCustomersRoute,
  {
    // @ts-ignore todo fix props
    name: MarketingPage.navName as string,
    // @ts-ignore todo fix props
    path: MarketingPage.url as string,
    // @ts-ignore todo fix props
    component: MarketingPage,
  },
  referralsPageRoute,
  {
    // @ts-expect-error
    name: OrderFeedbackPage.navName,
    // @ts-expect-error
    path: OrderFeedbackPage.url,
    // @ts-expect-error
    component: OrderFeedbackPage,
  },
  helpPageRoute,
  {
    name: WelcomePage.navName,
    path: WelcomePage.url,
    component: WelcomePage,
  },
];

export const deliveryCoordinatorRoutes: IRoute[] = [
  deliveryCoordinatorDeliveriesListRoute,
  adminDeliveriesRoute,
  referralsPageRoute,
  helpPageRoute,
];

export const restaurantDriverRoutes: IRoute[] = [
  driverDeliveriesListRoute,
  referralsPageRoute,
  helpPageRoute,
];

export const exchangeDriverRoutes: IRoute[] = restaurantDriverRoutes;

export default pageList;
