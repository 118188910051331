import { format } from 'date-fns';

export function convertToCSV({
  headers,
  rows,
  dateFormat,
}: {
  headers: string[];
  rows: unknown[][];
  dateFormat: string;
}): string {
  const processRow = (row: unknown[]): string => {
    return row.reduce((acc: string, cell, index) => {
      if (index > 0) {
        acc += ',';
      }
      if (cell instanceof Date) {
        try {
          acc += format(cell, dateFormat);
        } catch (e) {
          console.error(e, cell, dateFormat);
          acc += cell.toISOString();
        }
      } else if (typeof cell === 'object') {
        acc += JSON.stringify(cell);
      } else if (typeof cell === 'boolean') {
        acc += cell ? 'Tak' : 'Nie';
      } else {
        const innerValue =
          cell === null || cell === undefined
            ? ''
            : (cell as string | number).toString();
        let result: string = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = '"' + result + '"';
        }
        acc += result;
      }
      return acc;
    }, '');
  };

  const csvFile = [
    processRow(headers),
    ...rows.map((row) => processRow(row)),
  ].join('\n');

  return csvFile;
}

export function downloadFile({
  content,
  fileName,
  type,
}: {
  content: string;
  type: string;
  fileName: string;
}) {
  const blob = new Blob([content], { type });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
