import React, { Component } from 'react';
import { ModalTypes } from 'config';
import { IPageModalProps } from './WithSetModal';

const ModalContext = React.createContext<IModalContextState['value']>({
  modal: null,
  setModal: (modalBag: IPageModalProps, type?: ModalTypes) => {},
  closeModal: () => {},
});

export interface IModalContextState {
  value: {
    modal: IPageModalProps | null;
    setModal: (modalBag: IPageModalProps, type?: ModalTypes) => void;
    closeModal: () => void;
  };
}

export class ModalContextProvider extends Component<{}, IModalContextState> {
  constructor(props) {
    super(props);
    this.state = {
      value: {
        modal: null,
        setModal: this.setModal,
        closeModal: this.closeModal,
      },
    };
  }

  setModal = (modalBag: IPageModalProps, type = ModalTypes.CONFIRM) => {
    this.setValuePart({
      modal: {
        ...modalBag,
        type,
      },
    });
  };

  closeModal = () => {
    this.setValuePart({ modal: null });
  };

  setValuePart = (part) => {
    this.setState({
      value: {
        ...this.state.value,
        ...part,
      },
    });
  };

  render() {
    return (
      // @ts-ignore TODO type ModalContext properly
      <ModalContext.Provider value={this.state.value}>
        {this.props.children}
      </ModalContext.Provider>
    );
  }
}

export default ModalContext;
