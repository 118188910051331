import React, { useState } from 'react';
import { toast } from 'react-toastify';

import WithDateRangeDataFetched from 'app/containers/WithDateRangeDataFetched';
import DeliveriesTable from 'app/components/deliveries/DeliveriesTable';
import { OtoButtons, OtoToggle } from 'app/components/common';
import APIService from 'services/api';
import { getArrayWithUpdatedObject } from 'utils/array';
import { toDateInputValue } from 'utils/date-time';
import { downloadAdminDeliveriesCSV } from 'utils/csv';
import type { PageComponent } from 'app/types-global';
import type {
  IDelivery,
  IRawDeliveryWithAdminData,
  IFormattedDeliveryWithAdminData,
} from 'types';
import { EDeliveryStatus } from 'enums';
import { Card, CardBody } from 'reactstrap';

const getDeliveryTimestampByStatus = (
  delivery: IDelivery,
  status: EDeliveryStatus
): Date | null => {
  const time = delivery.status_logs?.find(
    (log) => log.status === status
  )?.created_at;
  return time ? new Date(time) : null;
};

export const AdminDeliveriesListPage: PageComponent<{}> = () => {
  const [showPrices, setShowPrices] = useState(true);
  const [showOrderDetails, setShowOrderDetails] = useState(true);
  const [showTimes, setShowTimes] = useState(true);
  const [loadingDeliveries, setLoadingDeliveries] = useState<number[]>([]);
  return (
    <>
      <div>
        <OtoToggle
          id="showDeliveriesPrices"
          label="Pokazuj ceny"
          checked={showPrices}
          onChange={() => setShowPrices(!showPrices)}
        />
        <OtoToggle
          id="showDeliveriesOrderDetails"
          label="Pokazuj szczegóły zamówienia"
          checked={showOrderDetails}
          onChange={() => setShowOrderDetails(!showOrderDetails)}
        />
        <OtoToggle
          id="showDeliveriesTimes"
          label="Pokazuj czasy"
          checked={showTimes}
          onChange={() => setShowTimes(!showTimes)}
        />
      </div>
      <WithDateRangeDataFetched<IRawDeliveryWithAdminData[]>
        getUrl={(startDate, endDate) => {
          const after = toDateInputValue(startDate);
          const before = toDateInputValue(endDate);
          return `/admin/deliveries?filter[created_after]=${after}&filter[created_before]=${before}&include=order,pricing,restaurant,restaurant.config,statusLogs,user`;
        }}
      >
        {(deliveriesWithPrices, { ranges, setData }) => {
          const deliveries: IFormattedDeliveryWithAdminData[] = deliveriesWithPrices.map(
            (delivery) => {
              return {
                ...delivery,
                assignedTime: getDeliveryTimestampByStatus(
                  delivery,
                  EDeliveryStatus.ASSIGNED
                ),
                collectionTime: getDeliveryTimestampByStatus(
                  delivery,
                  EDeliveryStatus.ON_THE_WAY
                ),
                deliveredTime: getDeliveryTimestampByStatus(
                  delivery,
                  EDeliveryStatus.DELIVERED
                ),
              };
            }
          );
          const handleUnassignDelivery = async (delivery: IDelivery) => {
            setLoadingDeliveries((prev) => [...prev, delivery.id]);
            return APIService.put(
              `/restaurants/${delivery.restaurant_id}/deliveries/${delivery.id}`,
              {
                assigned_to: null,
              }
            )
              .then((updatedDelivery) => {
                setLoadingDeliveries((prev) =>
                  prev.filter((id) => id !== updatedDelivery.id)
                );
                setData(getArrayWithUpdatedObject(deliveries, updatedDelivery));
                toast.success('Kierowca odpięty od dostawy');
              })
              .catch(() => {
                setLoadingDeliveries((prev) =>
                  prev.filter((id) => id !== delivery.id)
                );
                toast.error('Wystąpił problem z odpięciem kierowcy');
              });
          };

          const from = toDateInputValue(ranges[0].startDate);
          const to = toDateInputValue(ranges[0].endDate);
          const filename = `deliveries_${from}_${to}.csv`;

          return (
            <>
              <Card className={'my-2'}>
                <CardBody>
                  <div className={'text-danger'}>
                    Jeżeli próbujesz użyć wyszukiwarki - dla pól Kierowca,
                    Restauracja, Zamówienie - wyszukuj po numerze ID.
                  </div>
                </CardBody>
              </Card>
              <OtoButtons.DownloadButton
                color={'info'}
                onClick={() => downloadAdminDeliveriesCSV(deliveries, filename)}
              >
                Pobierz CSV
              </OtoButtons.DownloadButton>
              <DeliveriesTable
                deliveries={deliveries}
                isLoading={(delivery) =>
                  loadingDeliveries.includes(delivery.id)
                }
                onUnassignDriver={handleUnassignDelivery}
                showPrices={showPrices}
                showOrderDetails={showOrderDetails}
                showTimes={showTimes}
              />
            </>
          );
        }}
      </WithDateRangeDataFetched>
    </>
  );
};

AdminDeliveriesListPage.url = '/admin/deliveries';
AdminDeliveriesListPage.navName = 'admin.deliveries';

export default AdminDeliveriesListPage;
