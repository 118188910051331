import { useContext } from 'react';

import ModalContext from 'app/containers/ModalContext';

function useSetModal() {
  const modalBag = useContext(ModalContext);

  if (!modalBag) {
    throw new Error('useSetModal must be used within a ModalProvider');
  }

  return modalBag.setModal;
}

export default useSetModal;
