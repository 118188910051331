export enum EDeliveryType {
  USUAL = 'delivery',
  CONTACTLESS = 'contactless_delivery',
  ROBOT = 'robot_delivery',
  TAKEOUT = 'takeout',
  DRIVE_THROUGH = 'drive_through',
}

export enum EDeliveryStatus {
  NEW = 'new',
  ASSIGNED = 'assigned',
  ARRIVING = 'arriving',
  ARRIVED = 'arrived',
  ON_THE_WAY = 'on_the_way',
  DELIVERED = 'delivered',
  DECLINED = 'declined',
}

export enum EDriverType {
  Restaurant = 'restaurant',
  EXCHANGE = 'exchange',
  Foodeli = 'foodeli',
  Robot = 'robot',
  SzamaExpress = 'szamaexpress',
}
