import React, { useMemo } from 'react';
import { format } from 'date-fns';

import {
  IColumn,
  OtoButtons,
  OtoSpinner,
  ReactTableCellProps,
  SimpleTableWithSorting,
  generateColumn,
  getRTOriginal,
} from 'app/components/common';
import type { IPayment, IPaymentWitOrderInfo } from 'types';
import OrderCustomerCell from '../orders/OrderCustomerCell';
import OrderStatus from 'app/components/orders/order-parts/OrderStatus';
import OrderDelivery from 'app/components/orders/order-parts/OrderDelivery';

type AdminPaymentsListProps = {
  isPaymentLoading?: (payment: IPayment) => boolean;
  onRefundMadeClick?: (payment: IPaymentWitOrderInfo) => void;
  payments: IPaymentWitOrderInfo[];
};

type PaymentWithPlainOrderInfo = IPaymentWitOrderInfo & {
  orderId?: number;
  orderCustomer?: {
    customer_firstname: string;
    customer_lastname: string;
    phone: string;
  };
};

export const AdminPaymentsList: React.FC<AdminPaymentsListProps> = ({
  isPaymentLoading,
  onRefundMadeClick,
  payments: originalPayments,
}) => {
  const convertedPayments = useMemo<PaymentWithPlainOrderInfo[]>(() => {
    return originalPayments.map((payment) => {
      const { order } = payment;
      return {
        ...payment,
        orderId: order?.id,
        orderCustomer: order?.customer,
      };
    });
  }, [originalPayments]);

  const columns = useMemo<IColumn[]>(() => {
    return [
      generateColumn('id', {
        customHeader: 'Id u nas',
      }),
      generateColumn('external_id', {
        customHeader: 'Id zewnętrzne',
      }),
      generateColumn('amount', {
        customHeader: 'Kwota',
      }),
      generateColumn('status', {
        customHeader: 'Status płatności',
        filterType: 'select',
      }),
      generateColumn('type'),
      generateColumn('created_at', {
        customHeader: 'Data utworzenia',
        customCellFormatter: (
          props: ReactTableCellProps<IPaymentWitOrderInfo>
        ) => {
          const payment = getRTOriginal<IPaymentWitOrderInfo>(props);
          return format(new Date(payment.created_at), 'HH:mm dd.MM.yyyy');
        },
      }),
      generateColumn('paid_at', {
        customHeader: 'Data opłacenia',
        customCellFormatter: (
          props: ReactTableCellProps<IPaymentWitOrderInfo>
        ) => {
          const { paid_at } = getRTOriginal<IPaymentWitOrderInfo>(props);
          return paid_at
            ? format(new Date(paid_at), 'HH:mm dd.MM.yyyy')
            : 'nie opłacone';
        },
      }),
      generateColumn('orderId', {
        customHeader: 'Zamówienie',
        customCellFormatter: (
          props: ReactTableCellProps<PaymentWithPlainOrderInfo>
        ) => {
          const payment = getRTOriginal<PaymentWithPlainOrderInfo>(props);
          const { order, orderId, orderCustomer } = payment;
          return orderId && order ? (
            <>
              <div>#{orderId}</div>
              <div className="mb-2">
                <OrderStatus order={order} showPlainLabel={true} />
              </div>
              <hr className={'my-1'} />
              <OrderDelivery order={order} />
              <hr className={'my-1'} />
              {orderCustomer ? (
                <OrderCustomerCell customer={orderCustomer} showPhoneAsLink />
              ) : (
                'Brak klienta'
              )}
              <hr className={'my-1'} />
              <div>
                Restaurant:{' '}
                {order.restaurant
                  ? `${order.restaurant.name} (${order.restaurant.id})`
                  : order.restaurant_id}
              </div>
              {onRefundMadeClick && (
                <OtoButtons.GenericButton
                  color={'success'}
                  disabled={isPaymentLoading?.(payment) || false}
                  onClick={() => onRefundMadeClick(payment)}
                >
                  {isPaymentLoading?.(payment) && (
                    <OtoSpinner className={'mr-2'} />
                  )}
                  Zwrot dokonany
                </OtoButtons.GenericButton>
              )}
            </>
          ) : (
            'brak'
          );
        },
      }),
    ];
  }, [isPaymentLoading, onRefundMadeClick]);

  return (
    <div>
      <SimpleTableWithSorting
        data={convertedPayments}
        columns={columns}
        hasFilters
        pageSize={50}
      />
    </div>
  );
};

export default AdminPaymentsList;
