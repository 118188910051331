import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EyeOff } from 'react-feather';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import ReservationCustomersTable from 'app/components/reservations/ReservationCustomersTable';
import { selectRestaurant } from 'store/restaurant';
import UrlFetcher from 'app/containers/UrlFetcher';
import { FREQUENCY, ModalTypes } from 'config';
import ReservationsTable from 'app/components/reservations/ReservationsTable';
import { selectReservations } from 'store/reservations';
import { CollapsibleCard, OtoButtons } from 'app/components/common';
import type {
  ISavedReservation,
  TReservationCustomer,
  TReservationCustomerWithNotesData,
} from 'types';
import ViewReservationsFetcherWithRangeSelect from 'app/views/ViewReservationsFetcherWithRangeSelect';
import { normalizePhoneNumber } from 'utils/normalize-phone-number';
import Restaurant from 'utils/restaurant';
import {
  createCustomerNoteByPhone,
  getCustomerNotesByPhone,
  loadCustomerNotes,
  selectAddCustomerNoteLoading,
  selectCustomerNotes,
} from 'store/customers';
import GuestCard from 'app/components/reservations/GuestCard';
import useSetModal from 'hooks/useSetModal';
import { downloadReservationCustomersCSV } from 'utils/csv';
import i18next from 'i18next';

export const RESTAURANT_IDS_WITH_ACCESS_TO_RESERVATION_CUSTOMERS = [64, 141, 452, 468]

const ReservationCustomersPage = () => {
  const restaurant = useAppSelector(selectRestaurant);
  const rawReservations = useAppSelector(selectReservations);
  const allCustomerNotes = useAppSelector(selectCustomerNotes);
  const isNewCustomerNoteLoading = useAppSelector(selectAddCustomerNoteLoading);

  const dispatch = useAppDispatch();

  const [selectedCustomer, setSelectedCustomer] =
    useState<TReservationCustomer | null>(null);

  useEffect(() => {
    restaurant?.id && dispatch(loadCustomerNotes(restaurant.id));
  }, [dispatch, restaurant?.id]);

  const normalizedReservations = useMemo<ISavedReservation[]>(() => {
    return rawReservations.map((reservation) => ({
      ...reservation,
      phone_number: normalizePhoneNumber(reservation.phone_number),
    }));
  }, [rawReservations]);

  const selectedCustomerNotes = useMemo(() => {
    if (!selectedCustomer) {
      return [];
    }
    return getCustomerNotesByPhone(
      allCustomerNotes,
      selectedCustomer.phone_number
    );
  }, [allCustomerNotes, selectedCustomer]);

  const filteredReservations = useMemo(() => {
    if (!selectedCustomer) {
      return [];
    }
    return normalizedReservations.filter(
      (reservation) =>
        reservation.phone_number ===
        normalizePhoneNumber(selectedCustomer.phone_number)
    );
  }, [normalizedReservations, selectedCustomer]);

  const getReservationCustomersWithNotes = useCallback(
    (
      customers: TReservationCustomer[]
    ): TReservationCustomerWithNotesData[] => {
      return customers.map((customer) => {
        const customerNotes = getCustomerNotesByPhone(
          allCustomerNotes,
          customer.phone_number
        );
        return {
          ...customer,
          phone_number: normalizePhoneNumber(customer.phone_number),
          amount_of_customer_notes: customerNotes.length,
          customer_notes: customerNotes,
        };
      });
    },
    [allCustomerNotes]
  );

  const setModal = useSetModal();
  const downloadAllGuestsCSV = (customers: TReservationCustomer[]) => {
    setModal(
      {
        title: `Pobierasz listę wszystkich (${customers.length} os.) Gości`,
        text: (
          <>
            <div className={'mt-2'}>
              W poniższym CSV znajdują się dane osobowe wszystkich Gości.
            </div>
            <div className={'mt-2'}>
              Znaczna część tych danych została wprowadzona przez Restaurację, a
              system OtoStolik nie ma informacji o zgodach marketingowych,
              wyrażonych przez Gości.
            </div>
            <div className={'text-danger mt-2'}>
              Pamiętaj, że używając tych danych w celach marketingowych,
              odpowiedzialność za przetwarzanie danych osobowych ponosi
              Restauracja.
            </div>
          </>
        ),
        confirm: () => {
          const fileName = `lista-gosci-${restaurant?.slug}-pelna.csv`;
          downloadReservationCustomersCSV(customers, fileName);
        },
        confirmColor: 'info',
        confirmText: 'Zgadzam się, pobierz',
        cancelText: i18next.t('Cancel'),
      },
      ModalTypes.CONFIRM
    );
  };

  if (!restaurant?.config) {
    return null;
  }

  return (
    <>
      <ViewReservationsFetcherWithRangeSelect
        config={restaurant.config}
        defaultRange={'1-year'}
        frequency={FREQUENCY.REFRESH}
        includeTablesAndTheirPlaces={false}
        restaurant={restaurant}
        showToastOnError={true}
      />
      {selectedCustomer ? (
        <>
          <hr />
          <div className="h3 align-vertical gap-10">
            Szczegóły rezerwacji dla Gościa {selectedCustomer?.customer_name}{' '}
            (nr. telefonu {selectedCustomer?.phone_number})
            <OtoButtons.GenericButton
              color={'secondary'}
              icon={<EyeOff className={'mr-2'} size={16} />}
              onClick={() => setSelectedCustomer(null)}
            >
              Ukryj szczegóły
            </OtoButtons.GenericButton>
          </div>
          <CollapsibleCard buttonClassName={'p-1'} title={'Lista rezerwacji'}>
            <ReservationsTable
              bookingConfig={restaurant.config}
              reservations={filteredReservations}
              showMarketingAgreements={Restaurant.wantsToCollectMarketingAgreements(
                restaurant
              )}
              showNotes={true}
            />
          </CollapsibleCard>
          <GuestCard
            customerNotes={selectedCustomerNotes}
            isNoteBeingCreated={isNewCustomerNoteLoading}
            onNoteAdd={async (note) => {
              const createdNote = await dispatch(
                createCustomerNoteByPhone({
                  customerPhone: selectedCustomer.phone_number,
                  note,
                  restaurantId: restaurant.id,
                })
              );
              return !!createdNote;
            }}
          />
        </>
      ) : (
        <div>Wybierz Gościa, aby zobaczyć szczegóły rezerwacji</div>
      )}
      <UrlFetcher<TReservationCustomer[]>
        url={`/restaurants/${restaurant.id}/reservations/customers`}
      >
        {([reservationCustomers]) => (
          <>
            <hr />
            <div className="h3 gap-10 align-vertical">
              <span>Lista Gości ({reservationCustomers.length} os.)</span>
              <OtoButtons.DownloadButton
                onClick={() => downloadAllGuestsCSV(reservationCustomers)}
              >
                {'Pobierz CSV'}
              </OtoButtons.DownloadButton>
            </div>
            <ReservationCustomersTable
              onCustomerSelect={setSelectedCustomer}
              reservationCustomers={getReservationCustomersWithNotes(
                reservationCustomers
              )}
            />
          </>
        )}
      </UrlFetcher>
    </>
  );
};

export default ReservationCustomersPage;
