import React, { useMemo } from 'react';
import i18next from 'i18next';
import { Eye } from 'react-feather';

import type { TReservationCustomerWithNotesData } from 'types';
import {
  OtoButtons,
  ReactTableCellProps,
  SimpleTableWithSorting,
  generateColumn,
  getRTOriginal,
  TableDateFormatHelper,
} from 'app/components/common';
import { normalizePhoneNumber } from 'utils/normalize-phone-number';


export type ReservationsTableProps = {
  onCustomerSelect: (customer: TReservationCustomerWithNotesData) => void;
  reservationCustomers: TReservationCustomerWithNotesData[];
};

export const ReservationCustomersTable: React.FC<ReservationsTableProps> = ({
  onCustomerSelect,
  reservationCustomers,
}) => {
  const normalizedReservationCustomers = useMemo(() => {
    return reservationCustomers.map((reservationCustomer) => ({
      ...reservationCustomer,
      phone_number: normalizePhoneNumber(reservationCustomer.phone_number),
    }));
  }, [reservationCustomers]);

  const columns = useMemo(() => {
    return [
      generateColumn('customer_name', {
        customHeader: i18next.t('reservation.guest_name'),
        customCellFormatter: (
          props: ReactTableCellProps<TReservationCustomerWithNotesData>
        ) => {
          const reservationCustomer =
            getRTOriginal<TReservationCustomerWithNotesData>(props);
          return (
            <>
              {reservationCustomer.customer_name}
              <OtoButtons.GenericButton
                color={'secondary'}
                icon={<Eye className={'mr-2'} size={16} />}
                onClick={() => onCustomerSelect(reservationCustomer)}
              >
                Pokaż szczegóły
              </OtoButtons.GenericButton>
            </>
          );
        },
      }),
      generateColumn('amount_of_customer_notes', {
        customHeader: 'Liczba notatek',
      }),
      generateColumn('phone_number', {
        customHeader: i18next.t('reservation.phone_number'),
      }),
      generateColumn('email', {
        customHeader: i18next.t('reservation.email'),
      }),
      generateColumn('reservation_count', {
        customHeader: i18next.t('reservation-statistics.reservation_amount'),
        filterType: 'none',
      }),
      generateColumn('guests_number', {
        customHeader: i18next.t('reservation-statistics.guests_number'),
        filterType: 'none',
      }),
      generateColumn('min_reserved_on', {
        customHeader: 'Data pierwszej rezerwacji',
        customCellFormatter:
          TableDateFormatHelper<TReservationCustomerWithNotesData>('min_reserved_on'),
      }),
      generateColumn('max_reserved_on', {
        customHeader: 'Data ostatniej rezerwacji',
        customCellFormatter:
          TableDateFormatHelper<TReservationCustomerWithNotesData>('max_reserved_on'),
      }),
    ];
  }, [onCustomerSelect]);

  return (
    <>
      <SimpleTableWithSorting
        columns={columns}
        data={normalizedReservationCustomers}
        hasFilters
        pageSize={50}
      />
    </>
  );
};

export default ReservationCustomersTable;
