import React, { useMemo } from 'react';
import {
  OtoButtons,
  ReactTableCellProps,
  SimpleTableWithSorting,
  TableDateFormatHelper,
  generateColumn,
  getRTOriginal,
} from 'app/components/common';
import type { IDelivery, IFormattedDeliveryWithAdminData } from 'types';
import { toDateTimeInputValue } from 'utils/date-time';
import OrderDelivery from 'app/components/orders/order-parts/OrderDelivery';
import OrderPaymentType from 'app/components/orders/order-parts/OrderPaymentType';
import OrderTotal from 'app/components/orders/order-parts/OrderTotal';

type DeliveryType = IDelivery | IFormattedDeliveryWithAdminData;

export type DeliveriesTableProps = {
  deliveries: DeliveryType[];
  isLoading?: (delivery: DeliveryType) => boolean;
  onUnassignDriver?: (delivery: DeliveryType) => void;
  showOrderDetails?: boolean;
  showPrices?: boolean;
  showTimes?: boolean;
};

const DeliveriesTable: React.FC<DeliveriesTableProps> = ({
  deliveries,
  isLoading,
  onUnassignDriver,
  showOrderDetails,
  showPrices,
  showTimes,
}) => {
  const columns = useMemo(
    () => [
      generateColumn('created_at', {
        customHeader: 'Czas utworzenia',
        customCellFormatter: (props: ReactTableCellProps<DeliveryType>) => {
          const delivery = getRTOriginal<DeliveryType>(props);
          return toDateTimeInputValue(new Date(delivery.created_at));
        },
      }),
      generateColumn('address', {
        customHeader: 'Adres',
      }),
      generateColumn('distance', {
        customHeader: 'Odległość',
      }),
      generateColumn('price', {
        customHeader: 'Cena',
      }),
      generateColumn('payment_status', {
        customHeader: 'Typ płatności',
      }),
      generateColumn('restaurant_id', {
        customHeader: 'Restauracja',
        customCellFormatter: (props: ReactTableCellProps<DeliveryType>) => {
          const delivery = getRTOriginal<DeliveryType>(props);
          return delivery.restaurant
            ? `${delivery.restaurant.name} (${delivery.restaurant.id})`
            : delivery.restaurant_id;
        },
      }),
      ...(showOrderDetails
        ? [
            generateColumn('order_id', {
              customHeader: 'Zamówienie',
              customCellFormatter: (
                props: ReactTableCellProps<DeliveryType>
              ) => {
                const delivery = getRTOriginal<DeliveryType>(props);
                if (!('order' in delivery) || !delivery.order) {
                  return delivery.order_id;
                }

                return (
                  <>
                    <OrderDelivery order={delivery.order} />
                    <hr className={'my-1'} />
                    <OrderPaymentType order={delivery.order} />
                    <hr className={'my-1'} />
                    <OrderTotal order={delivery.order} showPrefix={false} />
                  </>
                );
              },
            }),
          ]
        : []),
      generateColumn('assigned_to', {
        customHeader: 'Kierowca',
        customCellFormatter: (props: ReactTableCellProps<DeliveryType>) => {
          const delivery = getRTOriginal<DeliveryType>(props);
          return delivery.user
            ? `${delivery.user.name} (${delivery.user.id})`
            : delivery.assigned_to;
        },
      }),
      generateColumn('driver_type', {
        customHeader: 'Driver Type',
        filterType: 'select',
      }),
      generateColumn('status', {
        customHeader: 'Status',
        filterType: 'select',
      }),
      ...(showPrices
        ? [
            generateColumn('priceForDriver', {
              customHeader: 'Wypłata kierowcy (brutto)',
            }),
            generateColumn('nettoCostForRestaurant', {
              customHeader: 'Koszt dla restauracji (netto)',
            }),
          ]
        : []),
      ...(showTimes
        ? [
            generateColumn('collectionTime', {
              customHeader: 'Godzina odbioru',
              customCellFormatter:
                TableDateFormatHelper<IFormattedDeliveryWithAdminData>(
                  'collectionTime',
                  'HH:mm dd.MM.yy'
                ),
            }),
            generateColumn('deliveredTime', {
              customHeader: 'Godzina dostarczenia',
              customCellFormatter:
                TableDateFormatHelper<IFormattedDeliveryWithAdminData>(
                  'deliveredTime',
                  'HH:mm dd.MM.yy'
                ),
            }),
          ]
        : []),
      generateColumn('id', {
        customHeader: 'ID',
        customCellFormatter: (props: ReactTableCellProps<DeliveryType>) => {
          const delivery = getRTOriginal<DeliveryType>(props);
          return (
            <>
              <div>{delivery.id}</div>
              {onUnassignDriver && delivery.assigned_to && (
                <OtoButtons.ReloadButton
                  outline
                  color={'secondary'}
                  loading={isLoading?.(delivery)}
                  onClick={() => onUnassignDriver(delivery)}
                >
                  Odepnij od kierowcy
                </OtoButtons.ReloadButton>
              )}
            </>
          );
        },
      }),
    ],
    [isLoading, onUnassignDriver, showOrderDetails, showPrices, showTimes]
  );
  return (
    <>
      <SimpleTableWithSorting columns={columns} data={deliveries} hasFilters />
    </>
  );
};

export default DeliveriesTable;
