import { toast } from 'react-toastify';
import { isElectron } from 'utils/general';
import Logger from 'utils/log';
import APIService from 'services/api';
import { IAppState } from './main';
import { selectRestaurant } from './restaurant';
import { hasProduct } from 'utils/restaurant';
import { AnyRestaruant } from 'types';
import { EPaymentType, OTO_PRODUCTS, Tenants } from 'enums';

export { Tenants };

export const INITIAL_STATE: ITenantsReducerState = {
  activeTenant: null,
  tenantsConfigs: null,
};

export interface ITenantsReducerState {
  activeTenant: Tenants | null;
  tenantsConfigs: Record<Tenants, APITenantConfig> | null;
}

export interface APITenantConfig {
  allowed_payment_option_keys: EPaymentType[];
  domain: string;
  label: string;
  themeColor: string;
  favicon: string;
  location: {
    lat: number;
    lng: number;
  };
  logo: string;
  image_resources_path: string;
  support: {
    technical: {
      email: string;
      phone: string;
    };
    operations: {
      email: string;
      phone: string;
    };
  };
}

interface IAction {
  type?: string;
  payload?: any;
}

const TENANTS_CONFIG_LOADED = 'Tenants/TENANTS_CONFIG_LOADED';

export default function tenantsReducer(
  state = INITIAL_STATE,
  action: IAction = {}
): ITenantsReducerState {
  switch (action.type) {
    case TENANTS_CONFIG_LOADED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

const getTenantByHost = (): Tenants => {
  if (window.location.hostname.includes('lubje')) {
    return Tenants.LUBJE;
  }
  if (isElectron()) {
    return Tenants.LUBJE;
  }
  if (window.location.hostname.includes('leszno')) {
    return Tenants.LesznoJe;
  }
  if (window.location.hostname.includes('ostrow')) {
    return Tenants.OstrowJe;
  }
  return Tenants.OtoStolik;
};

export const loadTenantsConfigs = () => async (dispatch: any) => {
  const url = `/tenants/config`;
  return APIService.post(url, {})
    .then((config) => {
      const stateToSet: ITenantsReducerState = {
        activeTenant: getTenantByHost(),
        tenantsConfigs: config,
      };
      return dispatch({
        type: TENANTS_CONFIG_LOADED,
        payload: stateToSet,
      });
    })
    .catch((e) => {
      Logger.fetchError({ e, url });
      toast.error('Wystąpił problem pobierania ustawień. Odśwież stronę.');
    });
};

export const selectActiveTenant = (state: IAppState): Tenants =>
  state.tenants.activeTenant || Tenants.OtoStolik;

export const selectTenantConfigs = (
  state: IAppState
): Record<Tenants, APITenantConfig> =>
  state.tenants.tenantsConfigs as Record<Tenants, APITenantConfig>;

export const selectActiveTenantConfig = (state: IAppState): APITenantConfig =>
  selectTenantConfigs(state)[selectActiveTenant(state)];

const getAppropriateTenantByRestaurantProducts = (
  restaurant: AnyRestaruant
): Tenants => {
  if (hasProduct(restaurant, OTO_PRODUCTS.ostrowje_orders)) {
    return Tenants.OstrowJe;
  }
  if (hasProduct(restaurant, OTO_PRODUCTS.lesznoje_orders)) {
    return Tenants.LesznoJe;
  }
  if (hasProduct(restaurant, OTO_PRODUCTS.lubje_orders)) {
    return Tenants.LUBJE;
  }
  return Tenants.OtoStolik;
};

export const selectActiveRestaurantTenantConfig = (
  state: IAppState
): APITenantConfig => {
  const restaurant = selectRestaurant(state);
  const appropriateTenant = restaurant
    ? getAppropriateTenantByRestaurantProducts(restaurant)
    : Tenants.OtoStolik;
  return selectTenantConfigs(state)[appropriateTenant];
};

export const selectResourcesPath = (state: IAppState) => {
  const { domain, image_resources_path } = selectActiveTenantConfig(state);
  if (image_resources_path.includes('http')) {
    return image_resources_path;
  }
  if (domain.includes('local')) {
    return `http://${domain}/${image_resources_path}`;
  }
  return `https://${domain}/${image_resources_path}`;
};
