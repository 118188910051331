import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import i18 from 'i18next';

import OtoSpinner from 'app/components/common/OtoSpinner';
import ViewToggler from 'app/components/common/ViewToggler';
import DeliveryTile from './DeliveryItem/DeliveryTile';
import GroupedFlow from '../common/GroupedFlow';
import { VIEWS } from 'config';
import { EDeliveryStatus } from 'enums';
import DeliveryEditModal from './DeliveryItem/DeliveryEditModal';
import Delivery, { isDelivered } from 'utils/deliveries';
import { Button } from 'reactstrap';
import { PERSISTENT_LS } from 'services/persistent';
import Shapes from 'shapes/main';
import type { IDelivery, User } from 'types';

import './RestaurateurDeliveriesList.scss';

const sortAssignedDeliveriesByArriveTime = (
  deliveries: IDelivery[],
  deliveryStatus: IDelivery['status']
) => {
  if (deliveryStatus !== 'assigned') {
    return deliveries;
  }
  return deliveries.sort((a, b) => {
    return (
      (Delivery.getDriverTimeToArrive(a) || 0) -
      (Delivery.getDriverTimeToArrive(b) || 0)
    );
  });
};

export const createDeliveriesFlow = memoizeOne((key = 'restaurant-view') => {
  let flow = {};
  Object.values(EDeliveryStatus).forEach((status) => {
    flow[status] = {
      label: i18.t(`delivery.${key}.statuses.${status}`),
    };
  });
  return flow;
});

type RestaurateurDeliveriesListProps = {
  canEditDeliveries: boolean;
  deliveries: IDelivery[];
  drivers: User[];
  loading: boolean;
  hasMultipleRestaurants: boolean;
  handleDeliveryUpdate: (
    delivery: IDelivery,
    payload: Partial<IDelivery>
  ) => void;
  handleDeliveryDelete: (delivery: IDelivery) => void;
};

type RestaurateurDeliveriesListState = {
  modalOpen: boolean;
  selectedDelivery?: IDelivery | null;
  isEditingModalMode: boolean;
  view: {
    text: 'grid' | 'list';
    icon: string;
  };
};

export default class RestaurateurDeliveriesList extends React.PureComponent<
  RestaurateurDeliveriesListProps,
  RestaurateurDeliveriesListState
> {
  static defaultProps = {
    deliveries: [],
    drivers: [],
  };

  static propTypes = {
    canEditDeliveries: PropTypes.bool.isRequired,
    deliveries: PropTypes.arrayOf(Shapes.deliveryShape).isRequired,
    drivers: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    hasMultipleRestaurants: PropTypes.bool.isRequired,
    handleDeliveryUpdate: PropTypes.func.isRequired,
    handleDeliveryDelete: PropTypes.func.isRequired,
  };

  static displayName = 'RestaurateurDeliveriesList';

  state: RestaurateurDeliveriesListState = {
    isEditingModalMode: false,
    modalOpen: false,
    view: VIEWS[PERSISTENT_LS.getDeliveriesView()],
  };

  changeView = (view) => {
    this.setState({ view });
    PERSISTENT_LS.setDeliveriesView(view === VIEWS.GRID ? 'GRID' : 'LIST');
  };

  closeModal = () => this.setState({ selectedDelivery: null });

  openModal = (delivery, isEditing) =>
    this.setState({
      selectedDelivery: delivery,
      isEditingModalMode: isEditing,
    });

  render() {
    const memoizedDeliveriesFlow = createDeliveriesFlow();
    const { loading, deliveries } = this.props;
    return (
      <div className="row mb-4 px-3">
        {loading && <OtoSpinner center className="mb-2" />}
        <ViewToggler
          activeView={this.state.view}
          className="mb-2"
          onViewChange={this.changeView}
        />
        <GroupedFlow
          fieldKey="status"
          data={deliveries}
          flow={memoizedDeliveriesFlow}
          emptyText="Brak dostaw w tej kategorii"
          renderItem={this.renderDelivery}
          rowClassName="d-flex flex-row flex-wrap"
          sortItemsInGroup={sortAssignedDeliveriesByArriveTime}
        />
        {!!this.state.selectedDelivery && (
          <DeliveryEditModal
            canEditDeliveries={this.props.canEditDeliveries}
            onClose={this.closeModal}
            delivery={this.state.selectedDelivery}
            drivers={this.props.drivers}
            handleDeliveryUpdate={this.props.handleDeliveryUpdate}
            isEditingMode={this.state.isEditingModalMode}
          />
        )}
      </div>
    );
  }

  renderButtons = (delivery: IDelivery) => {
    const historyButton = (
      <Button
        color="info"
        className="delivery-button"
        onClick={() => this.openModal(delivery, false)}
      >
        {i18.t('delivery.restaurant-view.history')}
      </Button>
    );
    if (isDelivered(delivery)) {
      return historyButton;
    }

    return (
      <div
        className={`delivery-buttons delivery-buttons--${this.state.view.text}`}
      >
        <Button
          color="success"
          className="delivery-button"
          onClick={() => this.openModal(delivery, true)}
        >
          {i18.t('Change')}
        </Button>
        {historyButton}
        {this.props.canEditDeliveries && (
          <Button
            color="danger"
            className="delivery-button"
            onClick={() => this.props.handleDeliveryDelete(delivery)}
          >
            {i18.t('Remove')}
          </Button>
        )}
      </div>
    );
  };

  renderDelivery = (delivery: IDelivery) => (
    <DeliveryTile
      key={delivery.id}
      delivery={delivery}
      view={this.state.view.text}
      showStatus={false}
      isDriver={false}
      showRestaurantName={this.props.hasMultipleRestaurants}
    >
      {this.renderButtons(delivery)}
    </DeliveryTile>
  );
}
