import React from 'react';
import { ModalTypes } from 'config';

import ModalContext from './ModalContext';
import type { IPageModalProps } from 'types';

export type { IPageModalProps };

export type TSetModalFunction = (
  modalProps: IPageModalProps,
  type: ModalTypes
) => void;

export interface WithSetModalProps {
  setModal: TSetModalFunction;
}

export default function withSetModal<P extends WithSetModalProps>(
  WrappedComponent: React.ComponentType<P>,
  staticComponentProps?: Record<string, any>
) {
  class withSetModal extends React.Component<Omit<P, 'setModal'>, {}> {
    static displayName = `WithSetModal(${getDisplayName(WrappedComponent)})`;
    static navName?: string;
    static url?: string;

    render() {
      return (
        <ModalContext.Consumer>
          {(modalBag) => (
            <WrappedComponent
              // @ts-ignore setModal is not actually being passed so no double passing here, just types
              setModal={modalBag.setModal}
              {...(this.props as P)}
            />
          )}
        </ModalContext.Consumer>
      );
    }
  }
  !!staticComponentProps &&
    Object.entries(staticComponentProps).forEach(([key, value]) => {
      withSetModal[key] = value;
    });
  return withSetModal;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
