import React from 'react';
import i18next from 'i18next';

import { OtoButtons } from 'app/components/common';
import { Tenants } from 'enums';

import type { TOriginalRestaurantWithMaybeFinancialConfig } from 'types';

type RestaurantFinancialSettingsProps = {
  onEditOrdersCommissionClick: (tenantName: Tenants) => void;
  onEditOnlinePaymentsCommissionClick: (tenantName: Tenants) => void;
  restaurant: TOriginalRestaurantWithMaybeFinancialConfig;
};

const RestaurantFinancialSettings: React.FC<
  RestaurantFinancialSettingsProps
> = ({
  onEditOrdersCommissionClick,
  onEditOnlinePaymentsCommissionClick,
  restaurant,
}) => {
  if (!restaurant.finance_settings) {
    return <div>Brak ustawień finansowych</div>;
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      <div>Prowizje od zamówień:</div>
      {!!restaurant.finance_settings.orders?.commission ? (
        Object.entries(restaurant.finance_settings.orders.commission).map(
          ([tenantName, tenantCommission]) => (
            <div key={tenantName} className="align-vertical">
              <span>
                {tenantName}: {tenantCommission?.orders}% prowizji podstawowej
              </span>
              <OtoButtons.EditButton
                onClick={() =>
                  onEditOrdersCommissionClick(tenantName as Tenants)
                }
              >
                {i18next.t('Change')}
              </OtoButtons.EditButton>
              <span>
                + {tenantCommission?.online_payments}% za płatności online
              </span>
              <OtoButtons.EditButton
                onClick={() =>
                  onEditOnlinePaymentsCommissionClick(tenantName as Tenants)
                }
              >
                {i18next.t('Change')}
              </OtoButtons.EditButton>
            </div>
          )
        )
      ) : (
        <div>Brak ustawień prowizji</div>
      )}
    </div>
  );
};

export default RestaurantFinancialSettings;
