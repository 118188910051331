import React from 'react';
import PropTypes from 'prop-types';

import Shapes from 'shapes/main';
import { EDeliveryStatus } from 'enums';
import { IDelivery } from 'types';

interface DriverDeliveryButtonsProps {
  delivery: IDelivery;
  changeStatus: (delivery: IDelivery, status: EDeliveryStatus) => void;
  requestDelay: (delivery: IDelivery) => void;
  proposeOtherTime: (delivery: IDelivery) => void;
}

const DriverDeliveryButtons = (props: DriverDeliveryButtonsProps) => {
  const { changeStatus, delivery, requestDelay, proposeOtherTime } = props;
  if (delivery.status === EDeliveryStatus.NEW) {
    return (
      <div className="row driver-deliveries-buttons-wrapper">
        <button
          className="btn btn-success nowrap mw-125"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            proposeOtherTime(delivery);
          }}
        >
          Akceptuje
        </button>
        <button
          className="btn btn-danger mw-125 nowrap"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            changeStatus(delivery, EDeliveryStatus.DECLINED);
          }}
        >
          Odrzucam
        </button>
      </div>
    );
  }

  const requestDelayBtn = (
    <button
      className="btn btn-info mw-200 nowrap"
      onClick={() => requestDelay(delivery)}
    >
      Potrzebuję więcej czasu
    </button>
  );

  if (delivery.status === EDeliveryStatus.ASSIGNED) {
    return (
      <div className="row driver-deliveries-buttons-wrapper">
        <button
          className="btn btn-success btn-block mw-150"
          onClick={() => changeStatus(delivery, EDeliveryStatus.ARRIVING)}
        >
          W Drodze do Restauracji
        </button>
        {requestDelayBtn}
      </div>
    );
  }
  
  if (delivery.status === EDeliveryStatus.ARRIVING) {
    return (
      <div className="row driver-deliveries-buttons-wrapper">
        <button
          className="btn btn-success btn-block mw-150"
          onClick={() => changeStatus(delivery, EDeliveryStatus.ARRIVED)}
        >
          W Restauracji
        </button>
        {requestDelayBtn}
      </div>
    );
  }

  if (delivery.status === EDeliveryStatus.ARRIVED) {
    return (
      <button
        className="btn btn-success mw-150"
        onClick={() => changeStatus(delivery, EDeliveryStatus.ON_THE_WAY)}
      >
        W Drodze
      </button>
    );
  }

  if (delivery.status === EDeliveryStatus.ON_THE_WAY) {
    return (
      <button
        className="btn btn-success mw-150"
        onClick={() => changeStatus(delivery, EDeliveryStatus.DELIVERED)}
      >
        Dostarczone
      </button>
    );
  }
  return null;
};

DriverDeliveryButtons.propTypes = {
  changeStatus: PropTypes.func.isRequired,
  delivery: Shapes.deliveryShape.isRequired,
  requestDelay: PropTypes.func.isRequired,
  proposeOtherTime: PropTypes.func.isRequired,
};

export default DriverDeliveryButtons;
