import ReservationsCalendarPage from '../app/pages/ReservationsCalendarPage';
import AddReservationPage from '../app/pages/AddReservation';
import PlaceMapViewPage from '../app/pages/PlaceMapViewPage';
import ReservationSettingsPage from '../app/pages/ReservationSettings';
import FindReservationPage from 'app/pages/FindReservationPage';
import { IRoute } from './types';
import ReservationCustomersPage, { RESTAURANT_IDS_WITH_ACCESS_TO_RESERVATION_CUSTOMERS } from 'app/pages/ReservationCustomersPage';

export const reservationListRoute: IRoute = {
  name: 'Lista rezerwacji',
  path: '/calendar',
  component: ReservationsCalendarPage,
};

export const addReservationRoute: IRoute = {
  name: 'Dodaj rezerwację',
  path: '/add-reservation/',
  component: AddReservationPage,
};

export const addReservationWithDateRoute: IRoute = {
  name: 'Dodaj rezerwację',
  path: '/add-reservation/:date',
  component: AddReservationPage,
};

export const addReservationWithDateAndTableRoute: IRoute = {
  name: 'Dodaj rezerwację',
  path: '/add-reservation/:date/:tableId',
  component: AddReservationPage,
};

export const placeMapViewRoute: IRoute = {
  name: 'Mapa stolików',
  path: '/place-map-view',
  component: PlaceMapViewPage,
};

export const findReservationRoute: IRoute = {
  name: 'Znajdź rezerwację',
  path: '/find-reservation',
  component: FindReservationPage,
};

export const reservationCustomersRoute: IRoute = {
  name: 'Lista klientów',
  path: '/reservation-customers',
  component: ReservationCustomersPage,
  availableForRestaurantIds: RESTAURANT_IDS_WITH_ACCESS_TO_RESERVATION_CUSTOMERS,
};

export const reservationSettingsRoute: IRoute = {
  name: 'Ustawienia rezerwacji',
  path: '/settings/reservations',
  // @ts-ignore todo fix props
  component: ReservationSettingsPage,
};
