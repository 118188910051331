import React, { useCallback, useMemo } from 'react';

import SimpleTableWithSorting, {
  generateColumn,
  getRTOriginal,
  IColumn,
  ReactTableCellProps,
} from 'app/components/common/SimpleTableWithSorting';
import { formatDate } from 'utils/date-time';
import type { TCustomer, TCustomerWithSourceAndDevices } from 'types';
import { Input, Label } from 'reactstrap';

type TCustomerType = TCustomer | TCustomerWithSourceAndDevices;

interface CustomersTableProps {
  customers: TCustomerType[];
  onCustomerSelect?: (customer: TCustomerType) => void;
  selectedCustomerIds?: number[];
}

export const CustomersTable: React.FC<CustomersTableProps> = ({
  customers,
  onCustomerSelect,
  selectedCustomerIds,
}) => {
  const renderSendPushNotificationButton = useCallback(
    (customer: TCustomerWithSourceAndDevices) => {
      const validDevices = customer.devices_with_invalid.filter(
        (device) =>
          device.valid_until && new Date(device.valid_until) > new Date()
      );
      return (
        <>
          <div className="small">
            {customer.devices_with_invalid.length} urządzeń, w tym{' '}
            {validDevices.length} aktywnych
          </div>
        </>
      );
    },
    []
  );

  const columns: IColumn[] = useMemo(
    () => [
      generateColumn('id', {
        customCellFormatter: (props: ReactTableCellProps<TCustomer>) => {
          const customer = getRTOriginal(props) as TCustomerType;
          return (
            <>
              <div>
                {onCustomerSelect && selectedCustomerIds ? (
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={selectedCustomerIds.includes(customer.id)}
                      onChange={() => onCustomerSelect(customer)}
                    />
                    <span>{props.value}</span>
                  </Label>
                ) : (
                  props.value
                )}
              </div>
              {'devices_with_invalid' in customer &&
                renderSendPushNotificationButton(customer)}
            </>
          );
        },
      }),
      generateColumn('name'),
      generateColumn('surname'),
      generateColumn('phone'),
      generateColumn('email'),
      {
        accessor: 'created_at',
        Header: 'Created At',
        Cell: (props) => (
          <div>{!!props.value ? formatDate(new Date(props.value)) : '-'}</div>
        ),
      },
      {
        accessor: 'updated_at',
        Header: 'Updated At',
        Cell: (props) => (
          <div>{!!props.value ? formatDate(new Date(props.value)) : '-'}</div>
        ),
      },
    ],
    [onCustomerSelect, renderSendPushNotificationButton, selectedCustomerIds]
  );

  return (
    <SimpleTableWithSorting
      columns={columns}
      data={customers}
      hasFilters
      pageSize={50}
    />
  );
};

export default CustomersTable;
