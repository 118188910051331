import { ROLE_GROUPS } from 'enums';
import { INavItem, INavItemWithChildren, INavItemWithUrl } from './types';
import AdminCustomersPage from 'app/pages/admin/AdminCustomersPage';
import AdminIntegrationsPage from 'app/pages/admin/AdminIntegrationsPage';
import AdminPayoutsPage from 'app/pages/admin/AdminPayoutsPage';
import AdminPaymentsPage from 'app/pages/admin/AdminPaymentsPage';
import AdminRestaurantsPage from 'app/pages/admin/AdminRestaurantsPage';
import AdminStatisticsPage from 'app/pages/admin/AdminStatisticsPage';
import AdminUsersPage from 'app/pages/admin/AdminUsersPage';
import OrdersDeclinationPage from 'app/pages/admin/OrdersDeclinationPage';
import RestaurantCompaniesPage from 'app/pages/admin/RestaurantCompaniesPage';
import DashboardPageForTenantAdmin from 'app/pages/admin/DashboardPageForTenantAdmin';
import AdminDeliveriesListPage from 'app/pages/admin/AdminDeliveriesPage';

const tenantAdminHomePageNavItem: INavItem = {
  name: DashboardPageForTenantAdmin.navName,
  icon: 'Coffee',
  url: DashboardPageForTenantAdmin.url,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

const adminRestaurantListPage = {
  name: (AdminRestaurantsPage as any).navName,
  icon: 'List',
  url: (AdminRestaurantsPage as any).url,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

const ordersDeclinationPage: INavItemWithUrl = {
  name: OrdersDeclinationPage.navName as string,
  icon: 'Clipboard',
  url: OrdersDeclinationPage.url as string,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

const adminDeliveriesPage: INavItemWithUrl = {
  name: AdminDeliveriesListPage.navName,
  icon: 'Truck',
  url: AdminDeliveriesListPage.url,
  availableForRoles: ROLE_GROUPS.allAdminRolesAndDeliveryCoordinator,
};

const adminPaymentsPage: INavItemWithUrl = {
  name: AdminPaymentsPage.navName,
  icon: 'DollarSign',
  url: AdminPaymentsPage.url,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

const adminPayoutsPage: INavItemWithUrl = {
  name: AdminPayoutsPage.navName,
  icon: 'DollarSign',
  url: AdminPayoutsPage.url,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

const adminIntegrationsPage: INavItemWithUrl = {
  name: AdminIntegrationsPage.navName as string,
  icon: 'Settings',
  url: AdminIntegrationsPage.url as string,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

const adminCustomersPage: INavItemWithUrl = {
  name: AdminCustomersPage.navName,
  icon: 'Users',
  url: AdminCustomersPage.url,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

export function getAdminNavPart(): INavItemWithChildren {
  return {
    name: 'admin.title',
    icon: 'User',
    availableForRoles: ROLE_GROUPS.allAdminRolesAndDeliveryCoordinator,
    children: [
      {
        name: AdminStatisticsPage.navName,
        icon: 'Activity',
        url: AdminStatisticsPage.url,
        availableForRoles: ROLE_GROUPS.allAdminRoles,
      },
      tenantAdminHomePageNavItem,
      adminRestaurantListPage,
      {
        name: AdminUsersPage.navName,
        icon: 'User',
        url: AdminUsersPage.url,
        availableForRoles: ROLE_GROUPS.allAdminRoles,
      },
      {
        name: RestaurantCompaniesPage.navName,
        icon: 'Home',
        url: RestaurantCompaniesPage.url,
        availableForRoles: ROLE_GROUPS.allAdminRoles,
      },
      ordersDeclinationPage,
      adminDeliveriesPage,
      adminPayoutsPage,
      adminPaymentsPage,
      adminIntegrationsPage,
      adminCustomersPage,
    ],
  };
}
