import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import i18next from 'i18next';
import { addDays, addWeeks, isValid, startOfDay, startOfWeek } from 'date-fns';
import { pl } from 'date-fns/locale';

import { InputWithOptions, OtoSpinner } from '../common';
import type { TDisableOrdersModalProps } from 'types';
import { REASONS_TO_CLOSE } from 'config';
import { formatDeliveryHours } from './DisableOrdersToggle';

interface IProps extends TDisableOrdersModalProps {
  closeModal: () => void;
}

export const disableUntilOptions = [
  {
    label: 'do końca dnia (włączą się automatycznie jutro)',
    value: startOfDay(addDays(new Date(), 1)).toISOString(),
  },
  { label: 'do odwołania (wymaga ręcznego włączenia)', value: '' },
  {
    label:
      'do następnego tygodnia (włączą się automatycznie w poniedziałek)',
    value: startOfWeek(addWeeks(new Date(), 1), { locale: pl }).toISOString(),
  },
];

const DisableOrdersModal: React.FC<IProps> = (props: IProps) => {
  const { closeModal, onValuesSelected, todayDeliveryHours } = props;

  const reasonOptions = useMemo(
    () => [
      { label: '', value: '' },
      ...REASONS_TO_CLOSE.map((reason) => ({
        ...reason,
        label: i18next.t(reason.label),
      })),
    ],
    []
  );

  const [reason, setReason] = useState(reasonOptions[0].value);
  const [disableUntil, setDisableUntil] = useState(
    disableUntilOptions[0].value
  );
  const isLoading = false;

  const disableUntilDate = new Date(disableUntil);

  return (
    <>
      <Modal isOpen>
        <ModalHeader toggle={closeModal}>
          {i18next.t('disable-orders-toggle.disabled-confirmation')}
        </ModalHeader>

        <>
          <ModalBody>
            <p>
              <span className="text-danger">
                Nie musisz wyłączać przyjmowania zamówień codziennie
              </span>{' '}
              - system blokuje możliwość złożenia zamówienia poza godzinami
              otwarcia ({formatDeliveryHours(todayDeliveryHours)})
              automatycznie.
            </p>
            <p>
              Wyłączaj zamówienia przy dużym ruchu, awarii lub innej sytuacji
              wyjątkowej.
            </p>
            <label className="font-weight-bold mt-2">Wybierz powód:</label>
            <InputWithOptions
              allowEmptyOnChange={false}
              value={reason}
              options={reasonOptions}
              onChange={(e) => setReason(e.target.value)}
            />
            <label className="font-weight-bold mt-4">
              Do kiedy wstrzymać zamówienia:
            </label>
            <Input
              type="select"
              value={disableUntil}
              onChange={(e) => setDisableUntil(e.target.value)}
            >
              {disableUntilOptions.map((opt) => (
                <option key={opt.label} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Input>
            <p className="mt-3">
              <strong className="d-block">
                Potrzebujesz zablokować zamówienia na inny dzień?
              </strong>{' '}
              <div>
                Możesz wyłączyć przyjmowanie zamówień dla konkretnej daty w
                zakładce{' '}
                <Link onClick={closeModal} to="/settings/special-days">
                  {i18next.t('nav.Ustawienia')} &gt;{' '}
                  {i18next.t('nav.Dni wyjątkowe')}
                </Link>
                .
              </div>
            </p>
          </ModalBody>
          <ModalFooter>
            {isLoading ? (
              <OtoSpinner />
            ) : (
              <>
                <Button type="button" color="secondary" onClick={closeModal}>
                  Zamknij
                </Button>
                <Button
                  type="button"
                  color="success"
                  onClick={() =>
                    onValuesSelected({
                      reasonToDisable: reason,
                      disableUntil: disableUntil,
                    }).finally(() => closeModal())
                  }
                  disabled={
                    !reason || (!!disableUntil && !isValid(disableUntilDate))
                  }
                >
                  {i18next.t('disable-orders-toggle.turn-off')}
                </Button>
              </>
            )}
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};

DisableOrdersModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default DisableOrdersModal;
