import { IFormattedDeliveryWithAdminData } from 'types';
import { convertToCSV, downloadFile } from './helpers';

export const downloadAdminDeliveriesCSV = (
  deliveries: IFormattedDeliveryWithAdminData[],
  fileName: string
): void => {
  const csvContent = generateAdminDeliveriesCSV(deliveries);
  downloadFile({
    content: csvContent,
    fileName,
    type: 'text/csv;charset=utf-8;',
  });
};

function generateAdminDeliveriesCSV(
  deliveries: IFormattedDeliveryWithAdminData[]
): string {
  const headers = [
    'ID',
    'Czas utworzenia',
    'Czas odbioru',
    'Czas dostarczenia',
    'Adres',
    'Odległość',
    'Cena',
    'Typ płatności (delivery)',
    'Koszt dla restauracji (netto)',
    'Wypłata kierowcy (brutto)',
    'Status',
    'Driver Type',
    'Restaurant ID',
    'Order ID',
    'Kwota zamówienia',
    'Koszt dostawy dla klienta',
    'Typ płatności (order)',
    'Kierowca',
  ];
  const rows = deliveries.map((delivery) => {
    const order = delivery.order;
    return [
      delivery.id,
      new Date(delivery.created_at),
      delivery.collectionTime ? new Date(delivery.collectionTime) : null,
      delivery.deliveredTime ? new Date(delivery.deliveredTime) : null,
      delivery.address,
      delivery.distance,
      delivery.price,
      delivery.payment_status, // TODO this is payment_type - to be renamed
      delivery.nettoCostForRestaurant,
      delivery.priceForDriver,
      delivery.status,
      delivery.driver_type,
      delivery.restaurant
        ? `${delivery.restaurant.name} (${delivery.restaurant.id})`
        : delivery.restaurant_id,
      delivery.order_id,
      order?.price,
      order?.delivery_cost,
      order?.payment_type,
      delivery.user
        ? `${delivery.user.name} ${delivery.user.id}`
        : delivery.assigned_to,
    ];
  });

  return convertToCSV({
    headers,
    rows,
    dateFormat: 'yyyy-MM-dd HH:mm',
  });
}
